export const getDefaultBillingControl = () => {
    return {
      orderBy: {
        orderBy: '',
        name: '',
        location: '',
        phone: '',
        fax: '',
        billTo: '',
        billToName: '',
        reference: '',
        billingDate: ''
      },
      equipmentDetails: {
        unitNum: '',
        chassis: '',
        equipmentSize: '',
        release: '',
        ssl: '',
        booking: '',
        voyage: '',
        vessel: '',
        destinationPort: '',
        equipmentType: '',
        secondaryUnit: '',
        secondaryChassis: '',
        chassisProvider: '',
        chassisRelease: '',
        hazMat: 'N',
        tanker: 'N',
        overWeight: 'N',
        reefer: 'N',
        residential: 'N',
      },
      customerNotes: {
        internalNotes: ''
      },
      pickup: {
        // pickupNumber: '',
        pickupPoint: '',
        pickupDate: '',
        pickupTime: '',
        disposition: '',
        returnDate: '',
        returnTime: '',
        chassisPickup: '',
        chassisPickupDate: '',
        chassisPickupTime: '',
        chassisDisposition: '',
        chassisReturnDate: '',
        chassisReturnTime: '',
        notifyDate: '',
        notifyTime: '',
        lastFreeDay: '',
        lastFreeTime: '',
        lastPerDiemDays: '',
        etaDate: '',
        etaTime: ''
      },
  
      shipper: {
        shipper: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        fax: '',
      },
  
      driverNotes: {
        driverNotes: '',
        driverNotes2: '',
      },
    }
  }

  export const orderTypeOptions = [
    {
      id: 1,
      value: "INBOUND",
      value2: "DOMINB"
    },
    {
      id: 2,
      value: "OUTBOUND",
      value2: "DOMOUT"
    },
    {
      id: 3,
      value: "IMPORT",
      value2: ""
    },
    {
      id: 4,
      value: "EXPORT",
      value2: ""
    },
  ];