import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Stack,
  Button
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ComponentWrapper } from "../../../components/styledComponents";
import {
  DataGridPro, useGridApiRef
} from "@mui/x-data-grid-pro";
import ExportSummaryByGlCode from './ExportSummaryByGlCode';
import { getGLCodes } from "../../../store/actions/rateBookActions";

export default function SummaryByGlCode(props) {
  const { activeTab, rawData, locationNumber, isLoading } = props;
  const [rows, setRows] = useState([]);
  const [totals, setTotals] = useState({
    receivables: 0,
    payables: 0,
    miscReceivables: 0,
    miscExpenses: 0
  });
  const [glCodesFetched, setGlCodesFetched] = useState(false);
  const apiRef = useGridApiRef();
  const rateBookReducer = useSelector((rateBookReducer) => rateBookReducer.rateBookReducer);
  const dispatch = useDispatch();
  
  const fetchGlCodes = async () => {
    await dispatch(getGLCodes()); // Await if your dispatch returns a promise
    setGlCodesFetched(true); // Indicate completion
  };
  
  useEffect(() => {
    const initializeData = async () => {
      if (rateBookReducer?.GLCodeData?.length === 0) {
        await fetchGlCodes();
      } else {
        setGlCodesFetched(true); // Already fetched, so we can proceed
      }
    };

    initializeData();
  }, [rateBookReducer?.GLCodeData]);
  
  useEffect(() => {
    if (glCodesFetched && rawData && locationNumber !== 0) {
      setRows(processDataForGlCode(rawData)); // Process data only after GL codes are fetched
    }
  }, [activeTab, glCodesFetched, rawData, locationNumber]);

  
  // Function to convert date range and process data
  const processDataForGlCode = (inputData) => {
    // Make a deep copy of the data to avoid mutating the original
    const clonedData = JSON.parse(JSON.stringify(inputData));
    const glCodeData = {
      id: 'glcode-row',
      receivables: [],
      payables: [],
      miscReceivables: [],
      miscExpenses: []
    };
    
    let total = {
      receivables: 0,
      payables: 0,
      miscReceivables: 0,
      miscExpenses: 0
    };
    let processedGLCodes = new Set();
    rateBookReducer.GLCodeData.forEach(item => {

      // Check if glCode has already been processed
      if (processedGLCodes.has(item.glcode)) {
        return; // Skip this iteration if the glCode is already processed
      }

      // Mark glCode as processed
      processedGLCodes.add(item.glcode);

      const entry = {
        glCode: item.glcode,
        glDescription: item.glDescription,
        rateAmount: 0,
        ratePercent: 0
      };
    
      // Sort based on glType
      switch (item.glType) {
        case 'P':
          glCodeData.payables.push(entry);
          break;
        case 'R':
          glCodeData.receivables.push(entry);
          break;
        case 'MR':
          glCodeData.miscReceivables.push(entry);
          break;
        case 'MP':
          glCodeData.miscExpenses.push(entry);
          break;
        default:
          // Handle unexpected types if needed
          break;
      }
    });
    
    // Process each input object
    clonedData.forEach(item => {
      const source = item;
      
      let rates = source.rates;
      
      rates.forEach(rate => {
        const { glCode, rateAmount } = rate;
      
        // Define an update function
        const updateRateAmount = (array, totalKey) => {
          const item = array.find(entry => entry.glCode === glCode);
          if (item) {
            item.rateAmount += rateAmount; // Add rateAmount to existing rateAmount
            total[totalKey] += rateAmount; // Add rateAmount to the corresponding total
            item.ratePercent = (item.rateAmount/total[totalKey] * 100)
          }
        };
      
        // Check and update each glCodeData array and corresponding total
        updateRateAmount(glCodeData.payables, "payables");
        updateRateAmount(glCodeData.receivables, "receivables");
        updateRateAmount(glCodeData.miscReceivables, "miscReceivables");
        updateRateAmount(glCodeData.miscExpenses, "miscExpenses");
      });
    });
    
    setTotals(total);
    
    const rows = createRows(glCodeData);
    
    return rows;
  }
  
  const createRows = (data) => {
    const maxLength = Math.max(
      data.receivables.length,
      data.payables.length,
      data.miscReceivables.length,
      data.miscExpenses.length
    );
  
    const rows = [];
    for (let i = 0; i < maxLength; i++) {
      rows.push({
        id: `row-${i}`,
        receivables: data.receivables[i] ? data.receivables[i]?.glCode || null : null,
        receivablesDesc: data.receivables[i] ? data.receivables[i]?.glDescription || null : null,
        receivablesAmt: data.receivables[i] ? `$${parseFloat(data.receivables[i]?.rateAmount).toFixed(2)}` || null : null,
        receivablesPercent: data.receivables[i] ? `${parseFloat(data.receivables[i]?.ratePercent).toFixed(2)}%` || null : null,
        payables: data.payables[i]? data.payables[i]?.glCode || null : null,
        payablesDesc: data.payables[i] ? data.payables[i]?.glDescription || null : null,
        payablesAmt: data.payables[i]? `$${parseFloat(data.payables[i]?.rateAmount).toFixed(2)}` || null : null,
        payablesPercent: data.payables[i]? `${parseFloat(data.payables[i]?.ratePercent).toFixed(2)}%` || null : null,
        miscReceivables: data.miscReceivables[i]? data.miscReceivables[i]?.glCode || null : null,
        miscReceivablesDesc: data.miscReceivables[i] ? data.miscReceivables[i]?.glDescription || null : null,
        miscReceivablesAmt: data.miscReceivables[i]? `$${parseFloat(data.miscReceivables[i]?.rateAmount).toFixed(2)}` || null : null,
        miscReceivablePercent: data.miscReceivables[i]? `${parseFloat(data.miscReceivables[i]?.ratePercent).toFixed(2)}%` || null : null,
        miscExpenses: data.miscExpenses[i]? data.miscExpenses[i]?.glCode || null : null,
        miscExpensesDesc: data.miscExpenses[i] ? data.miscExpenses[i]?.glDescription || null : null,
        miscExpensesAmt: data.miscExpenses[i]? `$${parseFloat(data.miscExpenses[i]?.rateAmount).toFixed(2)}` || null : null,
        miscExpensesPercent: data.miscExpenses[i]? `${parseFloat(data.miscExpenses[i]?.ratePercent).toFixed(2)}%` || null : null
      });
    }
    return rows;
  };

  const pinnedRows = {
    bottom: [{
      id: 'total-rows',
      receivables: 'Receivables',
      receivablesDesc: null,
      receivablesAmt: `$${totals.receivables.toFixed(2)}` || null,
      receivablesPercent: 100,
      payables: 'Payables',
      payablesDesc: null,
      payablesAmt: `$${totals.payables.toFixed(2)}` || null,
      payablesPercent: 100,
      miscReceivables: 'Misc Receivables',
      miscReceivablesDesc: null, 
      miscReceivablesAmt: `$${totals.miscReceivables.toFixed(2)}` || null,
      miscReceivablePercent: 100,
      miscExpenses:'Misc Expenses', 
      miscExpensesDesc: null,
      miscExpensesAmt: `$${totals.miscExpenses.toFixed(2)}` || null,
      miscExpensesPercent: 100
    }],
  };

  const tableColumns = [
    {
      field: 'receivables',
      headerName: 'Receivables',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'receivablesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'receivablesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'receivablesPercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'payables',
      headerName: 'Payables',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'payablesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'payablesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'payablesPercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscReceivables',
      headerName: 'Misc Receivables',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscReceivablesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscReceivablesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscReceivablePercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscExpenses',
      headerName: 'Misc Expenses',
      flex: 1.2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscExpensesDesc',
      headerName: 'Description',
      flex: 2,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscExpensesAmt',
      headerName: '$',
      flex: 1,
      renderCell: (params) => params.value ? params.value : null
    },
    {
      field: 'miscExpensesPercent',
      headerName: '%',
      flex: 0.8,
      renderCell: (params) => params.value ? params.value : null
    }
  ];
  
  
  const handleExportCsv = () => {
    apiRef.current.exportDataAsCsv();
  };
  
  return (
    <ComponentWrapper>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: 'absolute',
          //bottom:0,
          right: "1%",
          zIndex: 9999,
          marginTop: "-2%"
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={handleExportCsv}
          startIcon={<TextSnippetIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          CSV
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={ExportSummaryByGlCode([...rows, ...pinnedRows.bottom])}
          startIcon={<PictureAsPdfIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          PDF
        </Button>
      </Stack>
      <Stack
        //marginTop={2}
        sx={{
          height: window.innerHeight - 270,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(11, 67, 135, 1)",
            color: "white",
          },
          "& .isSubtotal": {
            fontWeight: "bold",
            // color: "blue",
            backgroundColor: "#E8E8E8 !important",
            // backgroundColor: "#F0F0F0 !important",
          },

          "& .pinned-row": {
            backgroundColor: "#f0f0f0",  /* Light gray background */
            fontWeight: "bold",          /* Bold text */
            color: "#000000"            /* Black text */
          },
          "& .pinned-row .MuiDataGrid-cell": {
            borderTop: "2px solid #000000"
          }

        }}
      >
        <DataGridPro
            apiRef={apiRef}
            hideFooter={true}
            rows={rows}
            columns={tableColumns}
            loading={isLoading}
            pinnedRows={pinnedRows}
            getRowId={(row) => row.id || `generated-${Math.random()}`}
            getRowClassName={(params) => params.id === 'total-rows' ? 'pinned-row' : ''}
        />
      </Stack>
    </ComponentWrapper>
  );
}
