import React, { Component, useState, useEffect, useRef, createRef } from "react";
import { Box, Stack, Typography, Snackbar, Alert, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ComponentWrapper } from "../../components/styledComponents";
import CustomTabPanel from "../../components/CustomTabPanel";
import Inbound from './Inbound';
import Outbound from './Outbound';

export default function Trace({ navigation }) {
    
    const [activeTab, setActiveTab] = useState(0);

    return (
        <ComponentWrapper>
            <Box
                sx={{
                    padding: 1
                }}
            >
                <Box mt={1} sx={{ flexDirection:"row" }}>
                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} aria-label="basic tabs example">
                        <Tab
                            label="Inbound" id='simple-tab-0'
                            sx={(activeTab === 0) ? {
                                backgroundColor: "#0911A7",
                                color: "white !important",
                                fontWeight: 500,
                                textTransform: 'capitalize',
                                borderRadius: '8%'
                            } : {}}
                        />
                        <Tab
                            label="Outbound" id='simple-tab-1'
                            sx={(activeTab === 1) ? {
                                backgroundColor: "#0911A7",
                                color: "white !important",
                                fontWeight: 500,
                                textTransform: 'capitalize',
                                borderRadius: '8%'
                            } : {}}
                        />

                    </Tabs>
                </Box>
                {/* </Stack>          
                </Stack> */}
                <CustomTabPanel value={activeTab} index={0}>
                    <Inbound/>
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                    <Outbound />
                </CustomTabPanel>
            </Box>
        </ComponentWrapper>
    );
}