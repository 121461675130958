import { useState, useEffect, useCallback } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

import AccessorialTableRow from './components/AccessorialTableRow';
import TableNewRowForm from './components/TableNewRowForm';
import SplitItemsTableRow from './components/SplitItemsTableRow';

//import action functions
import { deleteOrderRatesChargesData } from "../../../store/actions/orderActions";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export default function AccessorialRAC(props) {
    const {
        linehaulEditRef, accessorialEditRef, onChildNodeChange,
        orderType, ordersLocation, addNewRowBtnAcc, hideNewRow, FSCPercentage, snackBarHandler,
        billingDate, tableColumns, unPayableGLCodes,isTemplateScreen, isReceivableDisabled, isPayableDisabled } = props;

    const [rows, setRows] = useState(accessorialEditRef.current || []);
    const [billingDateCounter, setBillingDateCounter] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();

    const handleLineItemDelete = useCallback(async (lineNumber) => {
        let delReqParam = {
            location: params.locationNumber,
            control: params.controlNumber,
            lineNo: lineNumber
        }
        snackBarHandler({
            status: true,
            type: 'info',
            message: 'Deleting record...'
        });
        const apiRes = dispatch(deleteOrderRatesChargesData(delReqParam)).then((res) => {
            if (res?.status === 'success') {
                snackBarHandler({
                    status: true,
                    type: 'success',
                    message: res.message
                });
                return true;
            }
            snackBarHandler({
                status: true,
                type: 'error',
                message: res.message
            });
            return false;
        })
        return apiRes;
    });

    const handleNewRow = (data) => {
        accessorialEditRef.current.push(data)
        setRows(accessorialEditRef.current);
        onChildNodeChange(accessorialEditRef)
    }
    const handleExistingRow = (data, indx) => {
        accessorialEditRef.current[indx] = { ...data };
        setRows(accessorialEditRef.current);
        onChildNodeChange(accessorialEditRef)
    }

    useEffect(() => {
        setRows(accessorialEditRef.current);
    },[accessorialEditRef.current])
    
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead sx={{
                    backgroundColor: 'rgb(221, 228, 254)'
                }}>
                    <TableRow>
                        {tableColumns.map((column) => (
                            <TableCell align="center">{column.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, indx) => (
                        <>
                        <AccessorialTableRow
                            orderType={orderType}
                            rowIndex={indx}
                            key={indx}
                            row={row}
                            ordersLocation={ordersLocation}
                            linehaulEditRef={linehaulEditRef}
                            FSCPercentage={FSCPercentage}
                            accessorialEditRef={accessorialEditRef}
                            tableType={'A'}
                            onChildNodeChange={onChildNodeChange}
                            handleLineItemDelete={(lineNumber) => handleLineItemDelete(lineNumber)}
                            handleExistingRow={(data, indx) => handleExistingRow(data, indx)}
                            unPayableGLCodes={unPayableGLCodes}
                            snackBarHandler={snackBarHandler}
                            isTemplateScreen={isTemplateScreen}
                        />
                        {(row?.payable?.splitItems?.length > 0) ? (
                                row.payable.splitItems.map((splitItem, splitItemIndx) => (
                                    <SplitItemsTableRow
                                        orderType={orderType}
                                        row={splitItem}
                                        linehaulEditRef={accessorialEditRef}

                                        rowIndex={indx}
                                        onChildNodeChange={onChildNodeChange}
                                        splitType='parent'
                                        splitItemIndx={splitItemIndx}
                                        handleExistingRow={(data, indx) => handleExistingRow(data, indx)}
                                    />
                                ))
                            ) : null}
                            
                        </>
                    ))}
                    {addNewRowBtnAcc ? (
                        <TableNewRowForm
                            onChildNodeChange={onChildNodeChange}
                            orderType={orderType}
                            tableType='A'
                            linehaulEditRef={linehaulEditRef}
                            addNewRowBtnAcc={addNewRowBtnAcc}
                            hideNewRow={hideNewRow}
                            accessorialEditRef={accessorialEditRef}
                            handleNewRow={(data) => handleNewRow(data)}
                            isReceivableDisabled={billingDate !== '' || isReceivableDisabled ? true : false}
                            isPayableDisabled={isPayableDisabled ? true : false}
                            unPayableGLCodes={unPayableGLCodes}
                            ordersLocation={ordersLocation}
                            isTemplateScreen={isTemplateScreen}
                        />
                    ) : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}