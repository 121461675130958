import React, { useState } from "react";
import { Autocomplete, TextField, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

export default function DriversList(props) {
    const { valueChange, valueChangeObj } = props;
    const [value, setValue] = useState({ id: "", name: "" });
    const options = useSelector(({ loadsReducer }) => loadsReducer.driverList);

    return (
        <Autocomplete
            disableClearable
            size={`small`}
            options={options.filter(e => e.isAvailable === 'Y')} // && e.isTruckUsable === 'Y'
            getOptionLabel={(option) => `${(option.id) ? option.name : ''}`}
            value={value}
            onChange={(event, value, reason) => {
                if (typeof (value) === 'string') {
                    setValue({ id: value, name: "" });
                } else {
                    setValue(value);
                }

                if (typeof (value) === 'object') {
                    console.log(value)
                    if (typeof valueChange === "function") 
                        valueChange(value.id);

                    if (typeof valueChangeObj === "function") 
                        valueChangeObj(value)
                }
            }}
            style={{ width: '50%' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="driver"
                    variant="standard"
                    sx={{
                        input: {
                            textTransform: 'uppercase'
                        }
                    }}
                />
            )}
        />
    )
}