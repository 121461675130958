import jsPDF from "jspdf";
import "jspdf-autotable";
import { convertMilitaryDateToDDMMYY, formatCurrency, formatPercentageNum } from '../../../utils/global-functions';
const ExportSummaryByControl = (rows, tableColumns) => {

    const generatePDF = () => {
        if (!rows || rows.length === 0) {
            alert("No data available to generate PDF.");
            return;
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
            hotfixes: ['pxsplit'] // To prevent split issue in large tables
        });

        // Title Section
        doc.setFontSize(12);
        const title = "summary By Control# Report";
        const titleWidth = doc.getTextWidth(title);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        // doc.text(`Processed Date: ${processDate}`, 14, 30);
        // doc.text(`Vendor #${selectedVendor?selectedVendor:"ALL"} | Truck # ${selectedTruck?selectedTruck:"ALL"}`, 14, 40);

        // // Prepare table body
        const tableData = rows.map((row) => [
            row.controlNumber || "",
            row.totalLoads || "",
            row.customerName || "",
            row.billToCode || "",
            convertMilitaryDateToDDMMYY(row.billingDate) || "",
            row.reference || "",
            row.unitNumber || "",
            row.io || "",
            convertMilitaryDateToDDMMYY(row.apptDate) || "",
            row.PULoc || "",
            row.dispositionLoc || "",
            convertMilitaryDateToDDMMYY(row.returnDate) || "",
            row.shipperName || "",
            row.shipperAddress || "",
            row.consigneeName || "",
            row.consigneeAddress || "",
            formatCurrency(row.totalRevenue) || "",
            formatCurrency(row.totalNet) || "",
            formatPercentageNum(row.totalPercent) || "",
            formatCurrency(row.totalExpense) || "",
            formatCurrency(row.ptNet) || "",
            formatPercentageNum(row.ptPercent) || ""
        ]);
        const columnData = tableColumns.map(column => column.headerName);

        // AutoTable configuration for the PDF
        doc.autoTable({
            startY: 20,
            head: [columnData], //[["Control", "Total", "Control #", "Unit #", "Status", "Transmitted", "Payable Description", "Processed Date", "Rate", "Unit", "Amount", "PT%"]],
            body: tableData,
            styles: {
                fontSize: 8,
                cellPadding: 2,
                overflow: 'linebreak',
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0], // Header text color (black)
                lineWidth: 0.1,                // Set line width for borders
                lineColor: [0, 0, 0],          // Border color
            },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },
                2: { cellWidth: 'auto' },
                3: { cellWidth: 'auto' },
                4: { cellWidth: 'auto' },
                5: { cellWidth: 'auto' },
                6: { cellWidth: 'auto' },
                7: { cellWidth: 'auto' },
                8: { cellWidth: 'auto' },
                9: { cellWidth: 'auto' },
                10: { cellWidth: 'auto' },
                11: { cellWidth: 'auto' },
                12: { cellWidth: 'auto' },
                13: { cellWidth: 'auto' },
                14: { cellWidth: 'auto' },
                15: { cellWidth: 'auto' },
                16: { cellWidth: 'auto' },
                17: { cellWidth: 'auto' },
                18: { cellWidth: 'auto' },
                19: { cellWidth: 'auto' },
                20: { cellWidth: 'auto' },
                21: { cellWidth: 'auto' }
            },
            margin: { top: 10 },
            theme: 'grid',
        });

        // Save the PDF
        doc.save(`Summary-by-control-report.pdf`);
    };

    return generatePDF;
};

export default ExportSummaryByControl;