import { loadsTypes } from "../types/loadsTypes"
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk'
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import { GET as FILEDOWNLOADER } from '../../utils/axios-file-downloader';
interface Action {
    type: string,
    payload: any,
}

interface SearchValue {
    param: [],
    data: []
}

export interface SearchLoadsParams {
    available?: boolean,
    unassignedDate?: string,
    container: string,
    driverId?: string
}

export interface LoadLocation {
    city: string,
    state: string
}

export interface Load {
    appointmentDate: string,
    container: string,
    controlNumber: string
    disposition: LoadLocation,
    io: string,
    pickup: LoadLocation,
    stops: Stop[]
}

export interface Stop {
    location: LoadLocation,
    sequenceNumber: number,
}

export const selectLoad = (controlNumber: number): Action => {
    return {
        type: loadsTypes.SELECT_LOAD,
        payload: controlNumber
    }
}

const setLoad = (isLoading: boolean): Action => {
    return {
        type: loadsTypes.SET_LOAD_LOADING,
        payload: isLoading
    }
}

export const selectLoadReq = (controlNumber: number, locationNumber: number) => {
    return async (dispatch: Dispatch) => {
        dispatch(setLoad(true));
        const details = await getLoadDetails(controlNumber, locationNumber);
        dispatch(selectLoad(controlNumber));
        dispatch(setLoadDetails(details));
        dispatch(setLoad(false))
    }
}

export const getAllLoads = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setLoad(true));
            const allLoads = await GET('/loads');
            dispatch(setLoads(allLoads));

        } catch (err) {
            dispatch(setLoads([]));

        } finally {
            dispatch(setLoad(false));
        }
    }
}

export const setLoads = (loads: Load[]): Action => {
    return {
        type: loadsTypes.SET_LOAD,
        payload: loads,
    }
}

const getLoadDetails = async (controlNumber: number, locationNumber: number) => {
    try {
        const locQry = (locationNumber) ? `?location=${locationNumber}` : '';
        const loadDetails = await GET(`/loads/${controlNumber}/details${locQry}`);
        return loadDetails;
    } catch (err) {
        return []
    }
}

const setLoadDetails = (loadDetails: any): Action => {
    return {
        type: loadsTypes.SET_LOAD_DETAILS,
        payload: loadDetails
    }
}

export const getDriverList = () => {
    return async (dispath: Dispatch) => {
        const driverList = await getDriverListReq();
        dispath(setDriverList(driverList));
    }
}

const getDriverListReq = async () => {
    try {
        const driverList = await GET(`/drivers`);
        return driverList;
    } catch (err) {
        return [];
    }
}

export const getDriverListByLocation = (searchValue: SearchValue) => {
    return async (dispath: Dispatch) => {
        const driverList = await getDriverListByLocationReq(searchValue);
        dispath(setDriverList(driverList));
    }
}

const getDriverListByLocationReq = async (searchValue: any) => {
    if(searchValue){
    const searchStr = (searchValue !== undefined) ? `?location=${searchValue}` : '';
    try {
        const driverList = await GET(`/drivers${searchStr}`);
        return driverList;
    } catch (err) {
        return [];
    }
}else{
    return [];
}
}

const setDriverList = (driverList: []): Action => {
    return {
        type: loadsTypes.SET_DRIVERS,
        payload: driverList
    }
}

export const setDriverInfo = (driverInfo: any): Action => {
    return {
        type: loadsTypes.SET_DRIVER_INFO,
        payload: driverInfo
    }
}

//location lists
export const getLocationList = () => {
    return async (dispath: Dispatch) => {
        const locationList = await getLocationListReq();
        dispath(setLocationList(locationList));
    }
}

const getLocationListReq = async () => {
    try {
        const locationList = await GET(`/locations`);
        return locationList;
    } catch (err) {
        return [];
    }
}

const setLocationList = (locationList: []): Action => {
    return {
        type: loadsTypes.SET_LOCATIONS,
        payload: locationList
    }
}

export const tenderStops = (stops: Stop[], driverId: string, isDrop: boolean, controlNumber: string) => {
    return async (dispatch: ThunkDispatch<any, any, any>) => {
        await postTenderStops(stops, driverId, isDrop, controlNumber);
        dispatch(getAllLoads());
    }
}

const postTenderStops = async (stops: Stop[], driverId: string, drop: boolean, controlNumber: string) => {
    const tenderStops = await POST(`/loads/${controlNumber}/tender`, { stops, driverId, drop });
    return tenderStops;
}

export const tenderDispatchStops = (postData: any) => {
    return async (dispatch: ThunkDispatch<any, any, any>) => {
        return await postTenderDispatchStops(postData);
    }
}

const postTenderDispatchStops = async (postData: any) => { //(stops: Stop[], driverId: string, drop: boolean, controlNumber: string, io: string) => {
    const tenderStops = await POST(`/loads/${postData.locationNumber}/${postData.controlNumber}/tender`, postData);
    return tenderStops;
}

export const searchLoads = (searchValue: SearchValue) => {
    return async (dispatch: ThunkDispatch<any, any, any>) => {
        try {
            dispatch(setLoad(true));
            const response = await searchLoadsReq(searchValue);
            dispatch(setLoads(response));
        }
        catch (err) {
            dispatch(setLoads([]));
        } finally {
            dispatch(setLoad(false));
        }
    }
}

//loads/search?container=ABCDE12345&driverId=92472&unassignedDate=2021-06-11&available=false$location=123

const searchLoadsReq = async (searchValue: SearchValue) => {
    //const searchParams = searchValue.param.length > 0 ? `${searchValue.param}=${searchValue.data}` : '';
    try {
        const loads = await GET(`/loads/search?${searchValue}`);
        return loads;

    } catch (err) {
        return [];
    }
}

export const setSearchValues = (searchValues: object): Action => {
    return {
        type: loadsTypes.SET_SEARCH_VALUES,
        payload: searchValues
    }
}

export const setErrorData = (errorData: object) => {
    return {
        type: loadsTypes.SET_ERROR_MESSAGE,
        payload: errorData
    }
}

export const putLoadUpdate = async (locationNumber: number, controlNumber: number, update: object) =>
    await PUT(`/loads/${locationNumber}/${controlNumber}`, update);

export const getPOD = (locationNumber: number, controlNumber: number, stopNumber: number) => {
    return async (dispath: Dispatch) => {
        await FILEDOWNLOADER(`/loads/${locationNumber}/${controlNumber}/${stopNumber}/pod`, `POD-${controlNumber}.pdf`)
    }
}

// get Driver and Truck information action

export const getTruckDriverInfo = (driverId: Number) => {

    return async (dispatch: Dispatch) => {
        // dispatch(setLoad(true));
        let res = await getDriverTruckInfoData(driverId);

        if (res == "There is no data for requested driver Id!") {
            dispatch(getDriverTruckInfoAction({}))
            return res
        } else {
            dispatch(getDriverTruckInfoAction(res))
            // dispatch(setLoad(false));
        }
    }
}

const getDriverTruckInfoData = async (truckDriverInfo: any) => {
    let res = await GET(`/drivers/${truckDriverInfo.driverNumId}/${truckDriverInfo.locNumId}/${truckDriverInfo.truckNumId}/info`)
    if (res?.data?.length == 0) {
        return res.message;
    } else {
        return res?.data
    }

}

export const getDriverTruckInfoAction = (truckDriverInfo: any) => {
    return {
        type: loadsTypes.GET_TRUCK_DRIVER_INFO,
        payload: truckDriverInfo
    }
}

//Toggle Button Status change Action

export const driverToggle = (toggleStatus: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getDriverToggleAction(toggleStatus));
    }
}

export const getDriverToggleAction = (toggleStatus: any) => {
    return {
        type: loadsTypes.DRIVER_TOGGLE,
        payload: toggleStatus
    }
}

//Search Button Status change Action

export const submitSearch = (submitStatus: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(submitSearchAction(submitStatus));
    }
}

export const submitSearchAction = (submitStatus: any) => {
    return {
        type: loadsTypes.SUBMIT_SEARCH,
        payload: submitStatus
    }
}

//Screen Status change Action

export const screenChange = (screenStatus: any) => {

    return async (dispatch: Dispatch) => {
        dispatch(getScreenChangeAction(screenStatus));
    }
}

export const getScreenChangeAction = (screenStatus: any) => {
    return {
        type: loadsTypes.SCREEN_CHANGE,
        payload: screenStatus
    }
}

//Remove Tender Type

export const unTenderType = (unTenderValue: any) => {
    return async (dispatch: Dispatch) => {
        let res = await unTenderTypeData(unTenderValue);
        return res;
    }
}

const unTenderTypeData = async (unTenderValueParams: any) => {
    const { unTenderLoc, unTenderCntrlNum, unTenderStopNum } = unTenderValueParams;
    let res = await DELETE(`/loads/${unTenderLoc}/${unTenderCntrlNum}/${unTenderStopNum}`);
    return res;
}

const unTenderTypeAction = (unTenderValue: any) => {
    return {
        type: loadsTypes.REMOVE_TENDER_TYPE,
        payload: unTenderValue
    }
}

