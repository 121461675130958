import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatCurrency, formatPercentageNum } from '../../../utils/global-functions';
const ExportSummaryByGlCode = (rows) => {

    const generatePDF = () => {
        if (!rows || rows.length === 0) {
            console.error("No data available to generate PDF.");
            return;
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
            hotfixes: ['pxsplit'] // To prevent split issue in large tables
        });

        // Title Section
        doc.setFontSize(12);
        const title = "Summary By GL Code Report";
        const titleWidth = doc.getTextWidth(title);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        // doc.text(`Processed Date: ${processDate}`, 14, 30);
        // doc.text(`Vendor #${selectedVendor?selectedVendor:"ALL"} | Truck # ${selectedTruck?selectedTruck:"ALL"}`, 14, 40);
        
        const tableData = rows.map((row) => [
            row.receivables? row.receivables : '',
            row.receivables? row.receivablesDesc : '',
            row.receivables? row.receivablesAmt : '',
            row.receivables? `${parseFloat(row.receivablesPercent).toFixed(2)}%` : '',
            row.payables? row.payables : '',
            row.payables? row.payablesDesc : '',
            row.payables? row.payablesAmt : '',
            row.payables? `${parseFloat(row.payablesPercent).toFixed(2)}%` : '',
            row.miscReceivables? row.miscReceivables : '',
            row.miscReceivables? row.miscReceivablesDesc : '',
            row.miscReceivables? row.miscReceivablesAmt : '',
            row.miscReceivables? `${parseFloat(row.miscReceivablePercent).toFixed(2)}%` : '',
            row.miscExpenses? row.miscExpenses : '',
            row.miscExpenses? row.miscExpensesDesc : '',
            row.miscExpenses? row.miscExpensesAmt : '',
            row.miscExpenses? `${parseFloat(row.miscExpensesPercent).toFixed(2)}%` : '',
        ]);
        
        const columnData = ['Receivables', 'description', '$','%', 'Payables', 'description', '$','%', 'Misc Receivables', 'description', '$','%', 'Misc Expenses', 'description', '$', '%'];

        // AutoTable configuration for the PDF
        doc.autoTable({
            startY: 20,
            head: [columnData], //[["Control", "Total", "Control #", "Unit #", "Status", "Transmitted", "Payable Description", "Processed Date", "Rate", "Unit", "Amount", "PT%"]],
            body: tableData,
            styles: {
                fontSize: 8,
                cellPadding: 2,
                overflow: 'linebreak',
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0], // Header text color (black)
                lineWidth: 0.1,                // Set line width for borders
                lineColor: [0, 0, 0],          // Border color
            },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },
                2: { cellWidth: 'auto' },
                3: { cellWidth: 'auto' },
                4: { cellWidth: 'auto' },
                5: { cellWidth: 'auto' },
                6: { cellWidth: 'auto' },
                7: { cellWidth: 'auto' },
                8: { cellWidth: 'auto' },
                9: { cellWidth: 'auto' },
                10: { cellWidth: 'auto' },
                11: { cellWidth: 'auto' },
                12: { cellWidth: 'auto' },
                13: { cellWidth: 'auto' },
                14: { cellWidth: 'auto' },
                15: { cellWidth: 'auto' }
            },
            margin: { top: 10 },
            theme: 'grid',
        });

        // Save the PDF
        doc.save("Summary-by-gl-report.pdf");
    };

    return generatePDF;
};

export default ExportSummaryByGlCode;