import { useEffect, useState } from 'react';
// import material components
import {
    Tabs, Tab, Box, FormControlLabel,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    RadioGroup,
    Radio,
    FormControl,
    FormGroup,
    FormLabel
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// import DriverAutoComplete from './DriverAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { tenderDispatchStops, unTenderType } from '../../../store/actions/loadsActions';
import { getStopsData, setReloadTableState } from '../../../store/actions/dispatchActions';
import { apptDateGTE180d } from '../commonFn';
import DriversList from '../../../components/DriverInfo/DriversList';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const options = [
    { label: 'Stay With', value: 'staywith' },
    { label: 'Drop', value: 'drop' },
    { label: 'Pull', value: 'pull' }
];
const ioMap = { 'I': 'inbound', 'O': 'outbound' }
export default function TenderView(props) {
    const dispatch = useDispatch();
    const { modalState, handleModalState, type, row } = props;
    const [value, setValue] = useState(0);
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const [snackState, setSnackState] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [tenderPayload, setTenderPayload] = useState({
        stops: [row?.lineNumber || 0],
        driverId: 0,
        drop: '',
        controlNumber: row?.control || 0,
        io: (row.io !== '') ? ioMap[row.io] : null,
        locationNumber: row.locationNumber,
        tenderStatus: (value === 0) ? 'T' : 'A',
    });
    const stopsData = useSelector(
        ({ dispatchReducer }) => dispatchReducer.stopsData
    );

    const handleTenderSubmit = () => {
        setSnackState(true);
        setDisableSubmitButton(true);
        setSnackContent({ severity: "info", message: "Saving Tender changes..." })

        if (tenderPayload.driverId !== 0 && tenderPayload.controlNumber !== 0 && tenderPayload.drop !== '') {
            if (type === 'driver') {
                dispatch(unTenderType({ unTenderLoc: row.locationNumber, unTenderCntrlNum: row?.control, unTenderStopNum: row?.lineNumber })).then(res_0 => {
                    if (typeof res_0?.status !== 'undefined' && res_0.status === 'success') {
                        dispatch(tenderDispatchStops({ ...tenderPayload, tenderStatus: (value == 0) ? 'T' : 'A' }))
                            .then(res => {
                                if (typeof res?.message !== 'undefined') {
                                    setSnackContent({ severity: 'success', message: res.message })
                                    dispatch(setReloadTableState(true));
                                    setTimeout(() => {
                                        handleModalState(false);
                                        setDisableSubmitButton(false);
                                        //window.location.reload()
                                    }, 1000)
                                } else {
                                    setSnackContent({ severity: "error", message: 'something went wrong, please try again!' })
                                }
                            })
                    } else {
                        setSnackContent({ severity: "error", message: 'something went wrong to remove the initial driver details.' })
                    }
                })
            } else {
                dispatch(tenderDispatchStops({ ...tenderPayload, tenderStatus: (value == 0) ? 'T' : 'A' }))
                    .then(res => {
                        if (typeof res?.message !== 'undefined') {
                            setSnackContent({ severity: 'success', message: res.message })
                            dispatch(setReloadTableState(true));
                            setTimeout(() => {
                                handleModalState(false);
                                setDisableSubmitButton(false);
                                //window.location.reload()
                            }, 1000)
                        } else {
                            setSnackContent({ severity: "error", message: 'something went wrong, please try again!' })
                        }
                    })
            }

        } else {
            setDisableSubmitButton(false);
            setSnackContent({ severity: "error", message: 'Invalid Driver or Tender Type!' })
        }
    }

    const handleRadioChange = (val) => {
        console.log("value", val);
        if(!row.apptDate){
            alert("Appointment date for this stop is mandatory. Please fill it.");
                return; 
        }else{

        if (val === 'drop' || val === 'staywith') {
            const control = row.control;
            const stop = row.stop;
            // Find the stopData corresponding to the control number
            const stopDatum = stopsData.find(data => data.control === control && data.stop !== stop);
            if (stopDatum) {
                // Extract the stop index (e.g., "1/3" => 1)
                const stopIndex = parseInt(stop.split('/')[0]);

                // Check if there is a previous stop
                if (stopIndex > 1 && value===0) {
                    // Calculate the previous stop (e.g., "1/3" => "1/2")
                    const previousStop = (stopIndex - 1) + '/' + stop.split('/')[1];

                    // Find the previous stop in stopData
                    const previousStopDatum = stopsData.find(data => data.control === control && data.stop === previousStop);

                    // If previous stop is found
                    if (previousStopDatum) {
                        // Check if departTruck and departTruckStatus have values
                        if (previousStopDatum.departTruck !== "" && previousStopDatum.departTruckStatus !== "") {
                            // Previous stop meets conditions
                            setTenderPayload({ ...tenderPayload, drop: val });
                            return;
                        }else if(value===1){
                            setTenderPayload({ ...tenderPayload, drop: val });
                            return;

                        } else {
                            // Previous stop does not meet conditions
                            alert("Complete previous stop details.");
                            return;
                        }
                    }
                }
            }
            setTenderPayload({ ...tenderPayload, drop: val });
            return;
        } else if (val === 'pull') {
            const pullCondition =value===0?(row.arriveTruck !== "" && row.arriveTruckStatus !== ""):true
            if (pullCondition) {
                // Arrival truck details are complete
                setTenderPayload({ ...tenderPayload, drop: val });
                return;
            } else {
                // Arrival truck details are incomplete
                alert("Complete this stop's arrival truck details.");
                return;
            }
        }
        // Default case
        setTenderPayload({ ...tenderPayload, drop: val });
    }
    };
    return (
        <div className="mainContainer" >
            <Dialog
                fullWidth={true}
                maxWidth={`sm`}
                open={modalState} onClose={() => handleModalState(false)}>
                <DialogTitle sx={{ color: 'white', fontSize: '14px', backgroundColor: '#0B4387', height: '40px', alignContent: 'center', padding: '10px' }}>{(type === 'loads') ? `Load - ${row?.control}` : (type === 'driver') ? `Driver - ${row?.control}` : `Spotted - ${row?.control}`}</DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={(e, newVal) => {setValue(newVal);setTenderPayload({ ...tenderPayload, drop: "" })}} aria-label="basic tabs example">
                                <Tab label="Tender" {...a11yProps(0)} />
                                <Tab label="Assign" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Box>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormLabel>Driver</FormLabel>
                                    <DriversList 
                                        valueChangeObj={(val) => { 
                                            console.log('---', value)
                                            setTenderPayload({ ...tenderPayload, driverId: val.id, truckNumber:val.truck }); 
                                            setDisableSubmitButton(false) 
                                        }}
                                    />
                                    {/* <DriverAutoComplete
                                        valueChange={(val) => { setTenderPayload({ ...tenderPayload, driverId: val.driverId, truckNumber:val.truck }); setDisableSubmitButton(false) }}
                                    /> */}
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormGroup>
                                        <FormLabel >Tender Type</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="ringtone"
                                            name="ringtone"
                                            onChange={(event, val) => handleRadioChange(val)}
                                        >
                                            {options.map((option) => (
                                                <FormControlLabel
                                                    value={option.value}
                                                    key={option.value}
                                                    control={<Radio checked={tenderPayload.drop === option.value ? true : false} />}
                                                    label={option.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Box>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormLabel>Driver</FormLabel>
                                    {/* <DriverAutoComplete
                                        valueChange={(val) => { setTenderPayload({ ...tenderPayload, driverId: val.driverId, truckNumber:val.truck }); setDisableSubmitButton(false) }}
                                    /> */}
                                    <DriversList 
                                        valueChangeObj={(val) => { setTenderPayload({ ...tenderPayload, driverId: val.id, truckNumber:val.truck }); setDisableSubmitButton(false) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                                    <FormGroup>
                                        <FormLabel >Tender Type</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="ringtone"
                                            name="ringtone"
                                            onChange={(event, val) => handleRadioChange(val)}
                                        >
                                            {options.map((option) => (
                                                <FormControlLabel
                                                    value={option.value}
                                                    key={option.value}
                                                    control={<Radio checked={tenderPayload.drop === option.value ? true : false} />}
                                                    label={option.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </TabPanel>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModalState(false)} variant="outlined" size="small" color="primary">Cancel</Button>
                    <Button disabled={disableSubmitButton} onClick={() => handleTenderSubmit()} variant="contained" size="small" color="primary">Save</Button>
                </DialogActions>
                <Snackbar open={snackState} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackState(false)} >
                    <Alert onClose={() => setSnackState(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                        {snackContent.message}
                    </Alert>
                </Snackbar>
            </Dialog>
        </div>
    )
}