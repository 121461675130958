import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import {
  Stack,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Menu,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { TraceWrapper } from "../../../components/styledComponents";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import {
  getPTReportData,
  getTruckList,
  getVendorListSearch,
} from "../../../store/actions/reportActions";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import {
  formatedDateRangeFunc,
  formatDateRange,
  formatCurrency, 
  formatPercentageNum
} from "../../../utils/global-functions";
import PTReportPDF from "./component/PTReportPDF";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for expanded view
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function PTReport() {
  const dispatch = useDispatch();
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  // const [processDate, setProcessDate]=useState({startDate:"",endDAte:""})
  const [processDate, setProcessDate] = useState("");

  const [totalCheckboxState, setTotalCheckboxState] = useState({
    showSubtotal: true,
    showDailySubtotal: false,
    showTruckTotal: true,
    showVendorTotal: true,
    showOnlySummary:false
  });
  const[showOnlytotalRowsSummary,setShowOnlytotalRowsSummary]=useState(false)
  const [isTruckNoDate, setIsTruckNoDate] = useState(true);
  const [isTransmitted, setIsTransmitted] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedTruck, setSelectedTruck] = useState("");
  const [truckList, setTruckList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [showApplyButton, setShowApplyButton] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // For storing user input
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [sortModel, setSortModel] = useState({
    field :'', 
    sort : ''
  });
  const [totalRows, setTotalRows] = useState(0);
  useEffect(() => {
    if (typeof ordersLocation?.locationNumber !== "undefined") {
      // setShowApplyButton(false);

      handleResetChanges()
      // fetchDataFromServer();
      fetchTruckVendorList();
    }
  }, [ordersLocation]);

  const fetchTruckVendorList = () => {
    dispatch(getTruckList(ordersLocation.locationNumber)).then((res) => {
      setIsLoading(false);
      setTruckList(res);
    });
  };

  const fetchDataFromServer = (
    pageSizeNo = paginationModel.pageSize,
    pageNo = paginationModel.page,
    sortBy = sortModel.field, 
    sortOrder = sortModel.sort
  ) => {
    setSortModel((prev) => ({
      ...prev,
      field:sortBy,
    sort:sortOrder
    }));
    if (processDate) {
      const [fromdate, todate] = processDate
        ? processDate.split("|")
        : ["", ""];
      setIsLoading(true);
      const params = {
        locationNumber: ordersLocation.locationNumber,
        payType: "P",
        transmitted: isTransmitted ? "Y" : "N",
        isTruckNull: !isTruckNoDate,
        isPayDateNull: isTruckNoDate,
        fromProcessDate: fromdate,
        toProcessDate: todate,
        truckNumber: selectedTruck,
        vendor: selectedVendor,
        sortBy: (sortBy==="unitValue" || sortBy==="pt")?"unit" :sortBy,
        sortOrder: sortOrder,
        page: pageNo + 1,
        size: pageSizeNo,
      };
      dispatch(getPTReportData(params)).then((res) => {
        setIsLoading(false);
        setRows(addSubtotals(res?.data));
        setTotalRows(res?.totalRows);
      });

      setShowApplyButton(false);
    } else {
      setRows([]);
    }
  };

  const addSubtotals = (data) => {
    const updatedRows = [];
    let subtotal = 0;
    // let dailySubtotal = 0;
    let dailySubtotalMap = {};
    let truckTotal = 0;
    let vendorTotal = 0;

    let subtotalRateValue = 0;
    let dailySubtotalRateValue = 0;

    let idCounter = data.length;
    data.forEach((row, index) => {
      const amount = Number(row.amount) || 0; // Defaults to 0 if not a number
      const rate = Number(row.rate) || 0;
      // Add original row
      updatedRows.push(row);
       // Use date and truck as key for tracking daily subtotals
       const dateTruckKey = `${row.processedDate}_${row.truck}`;
        
       // Initialize daily subtotal entry if not already present
       if (!dailySubtotalMap[dateTruckKey]) {
           dailySubtotalMap[dateTruckKey] = { amount: 0, rateValue: 0 };
       }
      subtotal += amount;
      // dailySubtotal += amount;
      truckTotal += row.truck ? amount : 0;
      vendorTotal += row.vendor ? amount : 0;
      subtotalRateValue += row.rate;
      // dailySubtotalRateValue += row.rate;
      dailySubtotalMap[dateTruckKey].amount += amount;
      dailySubtotalMap[dateTruckKey].rateValue += row.rate ? Number(row.rate) : 0;

      // Check if control number or date has changed
      const nextRow = data[index + 1];
      const controlChanged =
        (nextRow && nextRow.controlNumber !== row.controlNumber) || !nextRow;
      const dateChanged =
        (nextRow && nextRow.processedDate !== row.processedDate) || !nextRow;
      const truckChanged =
        (nextRow && nextRow.truck !== row.truck && row.truck) ||
        (!nextRow && row.truck);
      const vendorChanged =
        (nextRow && nextRow.vendor !== row.vendor && row.vendor) ||
        (!nextRow && row.vendor);

      // Add subtotal row if control changes
      if (totalCheckboxState.showSubtotal &&controlChanged) {
        updatedRows.push({
          truck:row.truck,
          id: ++idCounter,
          controlNumber:row.controlNumber,
          unitValue: "Subtotal",
          // description: "Subtotal",
          amount: subtotal.toFixed(2),
          status: "",
          pt: ((subtotal / subtotalRateValue) * 100).toFixed(2) + "%", // example
          // pt: ((subtotal / subtotalUnitValue)).toFixed(2) + '%', // example
          isSubtotal: true,
        });
        subtotal = 0; // reset for next group
        subtotalRateValue = 0;
      }

      // Add daily subtotal if date changes
      // if (totalCheckboxState.showDailySubtotal && dateChanged) {
      //   updatedRows.push({
      //     id: ++idCounter,
      //     controlNumber: "",
      //     unitValue: `Daily Subtotal ${row.processedDate}`,
      //     // description: `Daily Subtotal ${row.processedDate}`,
      //     amount: dailySubtotal.toFixed(2),
      //     status: "",
      //     pt: ((dailySubtotal / dailySubtotalRateValue) * 100).toFixed(2) + "%",
      //     // isDailySubtotal: true,
      //     isSubtotal: true,
      //   });
      //   dailySubtotal = 0; // reset for the next day
      //   dailySubtotalRateValue = 0;
      // }
       // Add daily subtotal row if date or truck changes
    //    if ((totalCheckboxState.showDailySubtotal) && (dateChanged || truckChanged)) {
    //     // Add the daily subtotal entry for each truck on the given date
        
    //         updatedRows.push({
    //             id: ++idCounter,
    //             processedDate: row.processedDate,
    //             truck: row.truck,
    //             unitValue: `Daily Subtotal for Truck ${row.truck}`,
    //             amount: dailySubtotalMap[dateTruckKey].amount.toFixed(2),
    //             status: "",
    //             pt: dailySubtotalMap[dateTruckKey].rateValue > 0 
    //                 ? ((dailySubtotalMap[dateTruckKey].amount / dailySubtotalMap[dateTruckKey].rateValue) * 100).toFixed(2) + "%" 
    //                 : "0%", // To handle cases where rateValue might be zero
    //             isSubtotal: true,
    //         });
  
    //     // Reset for the next group
    //     dailySubtotalMap[dateTruckKey] = { amount: 0, rateValue: 0 };
    // }
      if (totalCheckboxState.showTruckTotal &&truckChanged) {
        updatedRows.push({
          id: ++idCounter,
          controlNumber: "",
          truck:row.truck,
          unitValue: "Truck",
          // description: "Truck",
          amount: truckTotal.toFixed(2),
          status: "",
          pt: "",
          // isTruckTotal: true,
          isSubtotal: true,
        });
        truckTotal = 0; // reset for next group
      }
      if (totalCheckboxState.showVendorTotal &&vendorChanged) {
        updatedRows.push({
          id: ++idCounter,
          controlNumber: "",
          truck:row.truck,
          unitValue: "Vendor",
          vendor:row.vendor,
          // description: "Vendor",
          amount: vendorTotal.toFixed(2),
          status: "",
          pt: "",
          // isVendorTotal: true,
          isSubtotal: true,
        });
        vendorTotal = 0; // reset for next group
      }
      if (!nextRow) {
        updatedRows.push({
          id: ++idCounter,
          controlNumber: "",
          unitValue: "Total",
          // description: "Total",
          amount: row.totalAmount,
          status: "",
          pt: "",
          // isTotal: true,
          isSubtotal: true,
        });
      }
    });

    setShowOnlytotalRowsSummary(totalCheckboxState.showOnlySummary)

    //  Add subtotal row if control changes

    return updatedRows;
  };

  const tableColumns = [
    { field: "vendor",headerName: "Vendor #",minWidth: 50,flex: 1,sortable: true},
    { field: "truck",headerName: "Truck #",minWidth: 90,flex: 1,sortable: true},
    {
      field: "controlNumber",
      headerName: "Control #",
      minWidth: 90,
      flex: 1,
      sortable: true,

      renderCell: (params) => (
        <a
          href={`/orders/${ordersLocation.locationNumber}/${params.value}`}
          style={{ color: "blue", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.value}
        </a>
      ),
    },
    { field: "unit", headerName: "Unit #", minWidth: 90, flex: 1 ,sortable: false},
    { field: "status", headerName: "Status", minWidth: 90, flex: 0.5,sortable: false },
    { field: "transmitted",headerName: "Transmitted",minWidth: 90,flex: 0.5,sortable: false},
    { field: "description",headerName: "Payable Description",minWidth: 90,flex: 2,sortable: false},
    { field: "processedDate",headerName: "Processed Date",minWidth: 90,flex: 1,sortable: false},
    { field: "rate", headerName: "Rate", minWidth: 90, flex: 1,sortable: false, renderCell: (params) => (!isNaN(params.value)) ? formatCurrency(params.value): params.value},
    { field: "unitValue",headerName: "Unit",minWidth: 90,flex: 1,sortable: true},
    { field: "amount", headerName: "Amount", minWidth: 90, flex: 1 ,sortable: false, renderCell: (params) => (!isNaN(params.value)) ? formatCurrency(params.value): params.value},
    // { field: "pt", headerName: "PT%", minWidth: 90, flex: 1, sortable: true},
  ];

  const handleClosePicker = () => {
    setIsPickerOpen(false); // Close picker
  };
  const handleAccept = (index, dateValue) => {
    const { formattedStartDate, formattedEndDate } = formatDateRange(dateValue);
    const formatDate = formattedStartDate + "|" + formattedEndDate;
    setProcessDate(formatDate);
    handleClosePicker();
    setShowApplyButton(true);
  };

  const handleApplyChanges = () => {
    setPaginationModel({
      pageSize: 50,
      page: 0,
    });
    fetchDataFromServer(50, 0);
  };
  const handleResetChanges = () => {
    setProcessDate("");
    setTotalCheckboxState((prev)=>({
      showSubtotal: true,
      showDailySubtotal: false,
      showTruckTotal: true,
      showVendorTotal: true,
      showOnlySummary:false
    }))
    setIsTruckNoDate(true);
    setIsTransmitted(false);
    setSelectedVendor("");
    setSelectedTruck("");
    setShowApplyButton(true);
    setRows([])
    setSortModel((prev) => ({
      ...prev,
      field :'', 
    sort : ''
    }));
  };
  const generatePDF = PTReportPDF(
    rows,
    formatedDateRangeFunc(processDate),
    selectedTruck,
    selectedVendor
  );
  const fetchVendorData = useCallback(
    debounce(async (query) => {
      setLoading(true);
      try {
        // if (typeof ordersLocation?.locationNumber !== "undefined") {
        const response = await dispatch(
          getVendorListSearch({
            locationNumber: ordersLocation.locationNumber,
            searchVal: query,
          })
        );
        setVendorList(response);
        // }
        setShowApplyButton(true);
      } catch (error) {
        console.error("Error fetching vendor data", error);
      }
      setLoading(false);
    }, 300), // Adjust debounce timing (e.g., 300ms) to delay the API call
    []
  );
  const handleInputChange = (event, value) => {
    setSearchQuery(value); // Directly use the `value` from Autocomplete
    fetchVendorData(value); // Fetch vendor data as user types
    //
  };
  // const handlePageChange = (params) => {
  //   setPaginationModel((prev) => ({
  //     ...prev,
  //     page: params.page,
  //     pageSize: params.pageSize,
  //   }));
  //   fetchDataFromServer(params.pageSize, params.page); // Adjust page if needed (some grids use zero-indexed pages)
  // };
  const handleSortModelChange = (newSortModel) => {
    if (newSortModel.length) {
      const { field, sort } = newSortModel[0]; // Access the first sort model
      setSortModel({ field, sort });
      fetchDataFromServer(paginationModel.pageSize, paginationModel.page, field, sort);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTotalCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setTotalCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    setShowApplyButton(true);
  };
  const handleSummaryCheckboxChange=(event)=>{
    const checked=event.target.checked
    setTotalCheckboxState((prevState) => ({
      ...prevState,
      showSubtotal:  checked,
      showDailySubtotal: checked,
      showTruckTotal:  checked,
      showVendorTotal: checked ,
      showOnlySummary:checked
    }))
    setShowApplyButton(true);
  }

  return (
    <TraceWrapper>
      <Stack spacing={2}>
        <Box sx={{ padding: 2 }}>
          <Stack
            direction="column"
            spacing={2}
            boxShadow={2}
            padding={3}
            height={80}
          >
            <Stack direction="row" spacing={2}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  flexDirection: "columns",
                  color: "#0911A7",
                }}
              >
                Purchase Transportation Report
              </Typography>
              <div style={{ position: "relative" }}>
                <TextField
                  autoComplete="off"
                  label="Processed Date"
                  disablePortal
                  size="small"
                  className="autocomplestyl"
                  id="combo-box-demo"
                  value={formatedDateRangeFunc(processDate) || ""}
                  // value={processDate.startDate}
                  onClick={() => setIsPickerOpen(true)}
                  readOnly // Makes the field read-only, only clickable
                  variant="outlined"
                  style={{ cursor: "pointer", width: "100%" }} // Styling for better UI
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear date"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent calendar from opening
                            setProcessDate(null); // Clear the date
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {isPickerOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-10%",
                      left: "10%",
                      width: "max-content",
                      zIndex: 1000,
                      background: "#fff",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <CustomDateRangePicker
                      indexValue={0}
                      defaultDateRangeValue={processDate ? processDate : ""}
                      onDateSelect={handleAccept}
                      handleCloseDatePicker={handleClosePicker}
                      isRestrict={1}
                    />
                  </div>
                )}
              </div>
              <>
                <FormControl
                  sx={{ minWidth: 120 }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel id="truck-label">Truck</InputLabel>
                  <Select
                    labelId="truck-label"
                    id="truck-select"
                    value={selectedTruck}
                    onChange={(e) => {
                      setSelectedTruck(e.target.value);
                      setShowApplyButton(true);
                    }}
                    label="Truck"
                    endAdornment={
                      // Add clear icon inside the dropdown
                      selectedTruck && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear truck selection"
                            onClick={() => {
                              setSelectedTruck("");
                              setShowApplyButton(true);
                            }} // Clear the selected truck
                            edge="end"
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }} // Optional: to limit dropdown height
                  >
                    {truckList.length > 0
                      ? truckList.map((item) => (
                          <MenuItem key={item.truck} value={item.truck}>
                            {item.truck}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>

                {/* Vendor Dropdown */}
                <Autocomplete
                  id="vendor-autocomplete"
                  value={selectedVendor}
                  onChange={(event, newValue) => setSelectedVendor(newValue)} // Update selected vendor
                  inputValue={searchQuery}
                  onInputChange={handleInputChange} // Trigger fetch on input change
                  options={vendorList.map((item) => item.vendorNumber)} // Provide vendor options
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendor"
                      variant="outlined"
                      size="small"
                      style={{ minWidth: "150px" }}
                    />
                  )}
                  noOptionsText="No vendors found"
                />
              </>
             
             
             <div>
      <Button variant="outlined" onClick={handleClick}
        style={{
          backgroundColor: '#F0F0F0',
          color: '#000',
          border: '1px solid #ccc',
          textTransform: 'none',
          boxShadow: 'none',
          padding: '8px 16px',
          fontSize: '14px',
        }}>
      View Total Options
      {anchorEl ? (
        <ExpandLessIcon style={{ marginLeft: '8px' }} />

      ) : (
        <ExpandMoreIcon style={{ marginLeft: '8px' }} />
      )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
       
        <MenuItem>
        <FormControlLabel
                control={
                  <Checkbox
                    checked={isTransmitted}
                    onChange={(e) => {
                      setIsTransmitted(e.target.checked);
                      setShowApplyButton(true);
                    }}
                    color="primary"
                  />
                }
                label="Include Transmitted Record?"
              />
                 </MenuItem>
                 <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTruckNoDate}
                    onChange={(e) => {
                      setIsTruckNoDate(e.target.checked);
                      setShowApplyButton(true);
                    }}
                    color="primary"
                  />
                }
                label="Include PT with Truck and no Date"
              />
            </MenuItem>
            {/* <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                name="showSubtotal"
                checked={totalCheckboxState.showSubtotal}
                onChange={handleTotalCheckboxChange}
                color="primary"
              />
            }
            label="Show Orders Subtotals"
          />
        </MenuItem> */}
            <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                name="showSubtotal"
                checked={totalCheckboxState.showSubtotal}
                onChange={handleTotalCheckboxChange}
                color="primary"
              />
            }
            label="Show Orders Subtotals"
          />
        </MenuItem>
        {/* <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
              name="showDailySubtotal"
              checked={totalCheckboxState.showDailySubtotal}
              onChange={handleTotalCheckboxChange}
                color="primary"
              />
            }
            label="Show Daily Subtotals"
          />
        </MenuItem> */}
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
              name="showTruckTotal"
              checked={totalCheckboxState.showTruckTotal}
              onChange={handleTotalCheckboxChange}
                color="primary"
              />
            }
            label="Show Truck Totals"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
              name="showVendorTotal"
              checked={totalCheckboxState.showVendorTotal}
              onChange={handleTotalCheckboxChange}
                color="primary"
              />
            }
            label="Show Vendor Totals"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
              name="showOnlySummary"
              checked={totalCheckboxState.showOnlySummary}
              onChange={handleSummaryCheckboxChange}
                color="primary"
              />
            }
            label="Summary"
          />
        </MenuItem>
      </Menu>
    </div>
              {showApplyButton ? (
                <Button
                  variant="contained"
                  onClick={handleApplyChanges}
                  disabled={processDate ? false : true}
                >
                  APPLY
                </Button>
              ) : (
                <Button variant="contained" onClick={handleResetChanges}>
                  RESET
                </Button>
              )}
              <Stack>
                <IconButton
                  aria-label="clear vendor selection"
                  onClick={generatePDF} // Trigger the generatePDF function when clicked
                  disabled={showApplyButton}
                  size="small"
                >
                  <PictureAsPdfIcon disabled={showApplyButton} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            marginTop={5}
            sx={{
              height: window.innerHeight - 250,
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(11, 67, 135, 1)",
                color: "white",
              },
              "& .isSubtotal": {
                fontWeight: "bold",
                // color: "blue",
                backgroundColor: "#E8E8E8 !important",
                // backgroundColor: "#F0F0F0 !important",
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: 'white', // Set the sorting icon color to white
              }
            }}
          >
            <DataGridPro
              hideFooter={true}
              // rows={rows}
              rows={showOnlytotalRowsSummary ? rows.filter(row => row.isSubtotal) : rows}
              rowCount={totalRows}
              columns={tableColumns}
              loading={isLoading}
              getRowClassName={(params) => {
                if (params.row.isSubtotal) return "isSubtotal";
                return "";
              }}
              sortingOrder={['desc', 'asc']}
              sortingMode="server" // Enable server-side sorting
              sortModel={[sortModel]}
              onSortModelChange={handleSortModelChange}
              // paginationMode="server"
              // pagination={true}
              // paginationModel={paginationModel}
              // pageSizeOptions={[10, 25, 50]}
              // onPaginationModelChange={(params) => {
              //   handlePageChange(params);
              // }}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: paginationModel.pageSize,
              //       page: paginationModel.page,
              //     },
              //   },
              // }}
            />
          </Stack>
        </Box>
      </Stack>
    </TraceWrapper>
  );
}
