import React, { useContext } from "react";
import { TextField, Paper, Autocomplete ,IconButton,Tooltip,Button} from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import orderContext from '../orderContext';
import './Styles.css'
import { chassisDispositionLookup, chassisPickupLookup, dispositionLookup, pickupPointLookup} from "../../../store/actions/orderActions";

// import custom date-time picker
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
// import custom date picker
import CustomDatePicker from '../../../components/CustomDatePicker';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import PickupDispositionFormContainer from "../../Maintenance/PickupDisposition/PickupDispositionFormContainer"

export default function Shipper(props) {

    const { billingControlRef, selectedOrderType, disableFull ,isTemplateScreen,isEdiScreen} = props

    const dispatch = useDispatch();

    const orderReducer = useSelector(({ orderReducer }) => orderReducer)

    const [pickupPointSelect, setPickupPointSelect] = useState(billingControlRef.current.pickup.pickupPoint ? {
        code: billingControlRef.current.pickup.pickupPoint,
        name: ''
    }
        : null)
    const [dispositionSelect, setDispositionSelect] = useState(billingControlRef.current.pickup.disposition ? {
        code: billingControlRef.current.pickup.disposition,
        name: ''
    }
        : null)
    const [chassisPickupSelect, setChassisPickupSelect] = useState(billingControlRef.current.pickup.chassisPickup ? {
        code: billingControlRef.current.pickup.chassisPickup,
        name: ''
    }
        : null)
    const [chassisDispositionSelect, setchassisDispositionSelect] = useState(billingControlRef.current.pickup.chassisDisposition ? {
        code: billingControlRef.current.pickup.chassisDisposition,
        name: ''
    }
        : null)
        // const [EditMode, setEditMode] = useState(false);

    // const [pickupDetailState, setPickupDetailState] = useState({
    //     pickupNumber: '557788',
    //     pickupPoint: 'pickup',
    //     disposition: 'dispose location',
    //     chassisPickup: 'Chicago',
    //     chassisDisposition: 'Dispose',
    //     pickupDate: '2023-03-15',
    //     pickupTime: '11:57',
    //     returnDate: '2023-03-15',
    //     returnTime: '11:57',
    //     notifyDate: '2023-03-15',
    //     notifyTime: '11:57',
    // })
    const [pickupDetailState, setPickupDetailState] = useState(billingControlRef.current.pickup)
    const [openPickUpForm,setOpenPickUpForm]=useState(false)
    const [editDataSendToModal,setEditDataSendToModal]=useState("")

    const { setIsStateChanged } = useContext(orderContext);
    const [open, setOpen] = useState(false);
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('pickup details fields are changes')
        setIsStateChanged(true);
        /** END */
    }

    const updateBillingControlRef = (pickupDetails) => {
        billingControlRef.current = {
            ...billingControlRef.current,
            pickup: { ...pickupDetails }
        }
        handleFieldChanges()
    }

    const handlePickupPointChange = (event, value) => {
        console.log("---value",value)
        const currentValue = value ? value.code : ""

        setPickupPointSelect(value)
        setPickupDetailState({
            ...pickupDetailState,
            pickupPoint: currentValue
        })
        updateBillingControlRef({
            ...pickupDetailState,
            pickupPoint: currentValue
        })
    }
    const handleDispositionChange = (event, value) => {
        console.log(value)
        const currentValue = value ? value.code : ""
        setDispositionSelect(value)
        setPickupDetailState({
            ...pickupDetailState,
            disposition: currentValue
        })
        updateBillingControlRef({
            ...pickupDetailState,
            disposition: currentValue
        })

    }
    const handleChassisPickupChange = (event, value) => {
        console.log(value)
        const currentValue = value ? value.code : ""
        setChassisPickupSelect(value)
        setPickupDetailState({
            ...pickupDetailState,
            chassisPickup: currentValue
        })
        updateBillingControlRef({
            ...pickupDetailState,
            chassisPickup: currentValue
        })
    }
    const handleChassisDispositionChange = (event, value) => {
        console.log(value)
        const currentValue = value ? value.code : ""
        setchassisDispositionSelect(value)
        setPickupDetailState({
            ...pickupDetailState,
            chassisDisposition: currentValue
        })
        updateBillingControlRef({
            ...pickupDetailState,
            chassisDisposition: currentValue
        })
    }

    // Lookup handlers
    const pickupPointLookupTimeout = useRef(null)
    const dispositionLookupTimeout = useRef(null)
    const chassisPickupLookupTimeout = useRef(null)
    const chassisdispositionLookupTimeout = useRef(null)

    const handlePickupPointLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (pickupPointLookupTimeout.current)
                clearTimeout(pickupPointLookupTimeout.current)

            const timer = setTimeout(() => {
                dispatch(pickupPointLookup({ code: "code", value: value }))
            }, 1000)

            pickupPointLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    const handleDispositionLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (dispositionLookupTimeout.current)
                clearTimeout(dispositionLookupTimeout.current)

            const timer = setTimeout(() => {
                dispatch(dispositionLookup({ code: "code", value: value }))
            }, 1000)

            dispositionLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    const handleChassisPickupLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (chassisPickupLookupTimeout.current)
                clearTimeout(chassisPickupLookupTimeout.current)

            const timer = setTimeout(() => {
                dispatch(chassisPickupLookup({ code: "code", value: value }))
            }, 1000)

            chassisPickupLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    const handleChassisDispositionLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (chassisdispositionLookupTimeout.current)
                clearTimeout(chassisdispositionLookupTimeout.current)

            const timer = setTimeout(() => {
                dispatch(chassisDispositionLookup({ code: "code", value: value }))
            }, 1000)

            chassisdispositionLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handleAddEditPickupClick=()=>{
        setOpenPickUpForm(true)
    }
    const handleCloseModal=()=>{
        setOpenPickUpForm(false)
        setEditDataSendToModal("")
    }
    const handleAddEdiitModalData =(data,field,type)=>{
        setEditDataSendToModal({...data,field,type})

    }
    const handleAddPickupDispo = (value) => {
        console.log(editDataSendToModal.field);

        if (editDataSendToModal?.field) {
            const actionMap = {
                "PickUp": handlePickupPointChange,
                "Disposition": handleDispositionChange,
                "Chassis Pickup": handleChassisPickupChange,
                "Chassis Disposition": handleChassisDispositionChange
            };

            const action = actionMap[editDataSendToModal.field];
            if (action) {
                action("", value);
            }
        }
    };

    const handleButtonClick = () => {
        setOpen(!open);
      };


      const handleTooltipClose = () => {
        setOpen(false);
      };
    return (
        (isTemplateScreen ? (
        <div className="mainContainer width_100">
            <Divider
                textAlign="left"
                className="dividersty"
            >
                <Chip label="Pickup/Return Details" size="small" variant="outlined" />
            </Divider>

            <Paper style={{ display: 'flex', padding: '1rem', marginTop: '1em', width: 'auto', maxWidth: '100%', flexDirection: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? 'row' : 'column' }} variant="outlined">
                <div style={{ width: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? "100%" : '100%', display: 'flex', flexDirection: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? 'row' : 'row', }}>

                    <div className="pickinguprow" style={{ display: 'flex', flexDirection: 'column', width: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? isTemplateScreen?"100%":'33.33%' : '50%' }} >
                    <div className={isTemplateScreen &&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') ?"pickinguprow":null} style={isTemplateScreen&&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT')?{ display: "flex", flexDirection: 'row' }:null} >
                        <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                        <Autocomplete
                                disablePortal
                                id="pickup-point-select"
                                size="small"
                                value={pickupPointSelect}
                                options={orderReducer.pickupPointLookupRes}
                                getOptionLabel={(option) => `${option.code} ${option.name}`}
                                className="width_ 100"
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                            {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                        </li>
                                    )
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} value={params.code} label="Pickup Point"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') && isEdiScreen && !pickupPointSelect ? 'red' : null,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') && isEdiScreen && !pickupPointSelect ? 'red' : null,
                                        },
                                    }
                                }}
                                />}
                                onInputChange={handlePickupPointLookup}
                                onChange={handlePickupPointChange}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 500
                                        }
                                    }
                                }}
                                sx={
                                    (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') ? {
                                        input: {
                                            background: isTemplateScreen?null:"#F8F0E3",
                                        }
                                    } : {}
                                }
                                disabled={disableFull}
                            />

                            </div>
                            <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       {/* { pickupPointSelect?<EditIcon/>: <AddIcon/>} */}
                       { pickupPointSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(pickupPointSelect,"PickUp","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(pickupPointSelect,"PickUp","Add")}}/>}

                      </IconButton>
                      {((selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT')  && isEdiScreen)?
                      (
                      <Tooltip
                        PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={open}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        //   title={"valueToMatch"}
                        title={<span style={{ fontSize: '16px', padding: '10px' }}>{`${billingControlRef?.current?.selectedStop?.name},${billingControlRef?.current?.selectedStop?.address},${billingControlRef?.current?.selectedStop?.city},${billingControlRef?.current?.selectedStop?.state}`}</span>}
                        sx={{
                          tooltip: {
                            maxWidth: 'none', // Removes the default max-width
                            backgroundColor: '#f5f5f9',
                            color: 'rgba(0, 0, 0, 0.87)',
                            boxShadow: 1,
                            fontSize: '14px',
                            padding: '10px',
                            border: '1px solid #dadde9',
                          },
                        }}
                        >
                          <IconButton onClick={handleButtonClick} disabled={disableFull}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>):null}
                      </div>

                        </div>
                        <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                            <Autocomplete
                                disablePortal
                                id="disposition-select"
                                size="small"
                                value={dispositionSelect}
                                options={orderReducer.dispositionLookupRes}
                                getOptionLabel={(option) => `${option.code} ${option.name}`}
                                className="width_ 100 "
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                            {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                        </li>
                                    )
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} value={params.code} label="Disposition"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') && isEdiScreen &&!dispositionSelect ? 'red' : null,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') && isEdiScreen && !dispositionSelect ? 'red' : null,
                                        },
                                    }
                                }}

                                />}
                                onInputChange={handleDispositionLookup}
                                onChange={handleDispositionChange}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 500
                                        }
                                    }
                                }}
                                sx={
                                    (selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') ? {
                                        input: {
                                            background:isTemplateScreen?null:"#F8F0E3",
                                        }
                                    } : {}
                                }
                                disabled={disableFull}
                            />

                            </div>
                            <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       { dispositionSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(dispositionSelect,"Disposition","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(dispositionSelect,"Disposition","Add")}}/>}
                      </IconButton>
                     {((selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') && isEdiScreen)?
                        (<Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            //   title={"valueToMatch"}
                            title={<span style={{ fontSize: '16px', padding: '10px' }}>{`${billingControlRef?.current?.selectedStop?.name},${billingControlRef?.current?.selectedStop?.address},${billingControlRef?.current?.selectedStop?.city},${billingControlRef?.current?.selectedStop?.state}`}</span>}
                            sx={{
                            tooltip: {
                             maxWidth: 'none', // Removes the default max-width
                             backgroundColor: '#f5f5f9',
                             color: 'rgba(0, 0, 0, 0.87)',
                             boxShadow: 1,
                             fontSize: '14px',
                             padding: '10px',
                             border: '1px solid #dadde9',
                            },
                            }}
                            >
                              <IconButton onClick={handleButtonClick} disabled={disableFull}>
                                <InfoIcon />
                              </IconButton>
                        </Tooltip>):null}
                      </div>
                        </div>
                        </div>
                        {
                            (selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') &&

                            (<>
                            <div className={isTemplateScreen &&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') ?"pickinguprow":null} style={isTemplateScreen&&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT')?{ display: "flex", flexDirection: 'row' }:null} >

                                <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="chassis-pickup-select"
                                        size="small"
                                        value={chassisPickupSelect}
                                        className="width_ 100"
                                        options={orderReducer.chassisPickupLookupRes}
                                        getOptionLabel={(option) => `${option.code} ${option.name}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                    {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                </li>
                                            )
                                        }}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} value={params.code} label="Chassis Pickup" />}
                                        onInputChange={handleChassisPickupLookup}
                                        onChange={handleChassisPickupChange}
                                        disabled={disableFull}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    width: 500
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                 <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       {/* { chassisPickupSelect?<EditIcon/>: <AddIcon/>} */}
                       { chassisPickupSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(chassisPickupSelect,"Chassis Pickup","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(chassisPickupSelect,"Chassis Pickup","Add")}}/>}

                      </IconButton>

                      </div>
                                </div>
                                <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="chassis-disposition-select"
                                        size="small"
                                        value={chassisDispositionSelect}
                                        className="width_ 100"
                                        options={orderReducer.chassisDispositionLookupRes}
                                        getOptionLabel={(option) => `${option.code} ${option.name}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                    {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                </li>
                                            )
                                        }}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} value={params.code} label="Chassis Disposition" />}
                                        onInputChange={handleChassisDispositionLookup}
                                        onChange={handleChassisDispositionChange}
                                        disabled={disableFull}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    width: 500
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       { chassisDispositionSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(chassisDispositionSelect,"Chassis Disposition","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(chassisDispositionSelect,"Chassis Disposition","Add")}}/>}
                      </IconButton></div>
                                </div>
                                </div>


                            </>)
                        }
                        {
                            (selectedOrderType === 'INBOUND') &&
                            (<>
                                <div className="width_99 paddingright" >
                                    <CustomDateTimePicker
                                        label="ETA Date & Time"
                                        size="small"
                                        className="width_100"
                                        value={`${pickupDetailState.etaDate}T${pickupDetailState.etaTime}` || ''}
                                        onValueChange={(timestamp) => {
                                            setPickupDetailState({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime });
                                            updateBillingControlRef({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime })
                                        }}
                                        disabled={disableFull}
                                    />
                                </div>

                            </>

                            )}
                        {
                            (selectedOrderType === 'INBOUND' || selectedOrderType === 'OUTBOUND') &&
                            (<>
                                <div className="width_99 paddingright" >
                                    <CustomDateTimePicker
                                        label={(selectedOrderType === 'EXPORT' || selectedOrderType === 'OUTBOUND') ? "Cutt Off Date & Time" : "Last Storage Date & Time"}
                                        size="small"
                                        className="width_100"
                                        value={`${pickupDetailState.lastFreeDay}T${pickupDetailState.lastFreeTime}` || ''}
                                        onValueChange={(timestamp) => {
                                            setPickupDetailState({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                            updateBillingControlRef({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime })
                                        }}
                                        disabled={disableFull}
                                        sx={
                                            (selectedOrderType === 'EXPORT') ? {
                                                input: {
                                                    background: "#F8F0E3"
                                                }
                                            } : {}
                                        }
                                    />
                                </div>

                            </>)
                        }

                    </div>


                  {!isTemplateScreen?(<>
                    <div className="pickinguprow width_100" style={{ display: 'flex', flexDirection: 'column', width: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? '33.33%' : '50%' }} >
                        <div className="width_99 paddingleft" >
                            <CustomDateTimePicker
                                label="Pickup Date & Time"
                                size="small"
                                className="width_100"
                                value={`${pickupDetailState.pickupDate}T${pickupDetailState.pickupTime}` || ''}
                                onValueChange={(timestamp) => {
                                    setPickupDetailState({ ...pickupDetailState, pickupDate: timestamp.eDate, pickupTime: timestamp.eTime });
                                    updateBillingControlRef({ ...pickupDetailState, pickupDate: timestamp.eDate, pickupTime: timestamp.eTime })
                                }}
                                disabled={disableFull}
                            />

                        </div>
                        <div className="width_99 paddingleft" >
                            <CustomDateTimePicker
                                label="Return Date & Time"
                                size="small"
                                className="width_100"
                                value={`${pickupDetailState.returnDate}T${pickupDetailState.returnTime}` || ''}
                                onValueChange={(timestamp) => {
                                    setPickupDetailState({ ...pickupDetailState, returnDate: timestamp.eDate, returnTime: timestamp.eTime });
                                    updateBillingControlRef({ ...pickupDetailState, returnDate: timestamp.eDate, returnTime: timestamp.eTime })
                                }}
                                disabled={disableFull}
                            />
                        </div>

                        {
                            (selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') &&

                            (<>

                                <div className="width_99 paddingleft" >
                                    <CustomDateTimePicker
                                        label="Pickup Date & Time"
                                        size="small"
                                        className="width_100"
                                        value={''}
                                        // onValueChange={(timestamp) => {}}
                                        disabled={true}
                                    />
                                </div>

                                <div className="width_99 paddingleft" >
                                    <CustomDateTimePicker
                                        label="Return Date & Time"
                                        size="small"
                                        className="width_100"
                                        value={''}
                                        // onValueChange={(timestamp) => {}}
                                        disabled={true}
                                    />

                                </div>

                            </>)
                        }
                        {
                            (selectedOrderType === 'INBOUND') &&
                            (
                                <div className="width_99 paddingleft" >
                                    <CustomDateTimePicker
                                        label={selectedOrderType === 'EXPORT' ? "ERD Date & Time" : "Notify Date & Time"}
                                        size="small"
                                        className="width_100"
                                        value={`${pickupDetailState.notifyDate}T${pickupDetailState.notifyTime}` || ''}
                                        onValueChange={(timestamp) => {
                                            setPickupDetailState({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                            updateBillingControlRef({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime })
                                        }}
                                        disabled={disableFull}
                                        sx={
                                            (selectedOrderType === 'EXPORT') ? {
                                                input: {
                                                    background: "#F8F0E3"
                                                }
                                            } : {}
                                        }
                                    />
                                </div>
                            )
                        }
                        {
                            (selectedOrderType === 'INBOUND' || selectedOrderType === 'OUTBOUND') &&
                            (<>
                                <div className="width_99 paddingleft" >

                                    <CustomDatePicker
                                        label="Last/Diem day"
                                        size="small"
                                        className="width_100"
                                        value={pickupDetailState.lastPerDiemDays || ''}
                                        onValueChange={(selectedVal) => {
                                            setPickupDetailState({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                            updateBillingControlRef({ ...pickupDetailState, lastPerDiemDays: selectedVal })
                                        }}
                                        disabled={disableFull}
                                    />
                                </div>
                            </>)

                        }


                    </div>
                    {/* ):null} */}

                    {
                        (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') &&
                        (
                            <div className="pickinguprow" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '0.2rem', width: '33.33%' }}>

                                {/* For IMPORT */}
                                {selectedOrderType === 'IMPORT' && (
                                    <>
                                        {/* ETA Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="ETA Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.etaDate}T${pickupDetailState.etaTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Notify Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="Notify Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.notifyDate}T${pickupDetailState.notifyTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Last Storage Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="Last Storage Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.lastFreeDay}T${pickupDetailState.lastFreeTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Last/Diem Day */}
                                        <div className="width_99 paddingleft">
                                            <CustomDatePicker
                                                label="Last/Diem Day"
                                                size="small"
                                                className="width_100"
                                                value={pickupDetailState.lastPerDiemDays || ''}
                                                onValueChange={(selectedVal) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                    updateBillingControlRef({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>
                                    </>
                                )}

                                {/* For EXPORT */}
                                {selectedOrderType === 'EXPORT' && (
                                    <>
                                        {/* ERD Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="ERD Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.notifyDate}T${pickupDetailState.notifyTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Cut Off Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="Cut Off Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.lastFreeDay}T${pickupDetailState.lastFreeTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Last/Diem Day */}
                                        <div className="width_99 paddingleft">
                                            <CustomDatePicker
                                                label="Last/Diem Day"
                                                size="small"
                                                className="width_100"
                                                value={pickupDetailState.lastPerDiemDays || ''}
                                                onValueChange={(selectedVal) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                    updateBillingControlRef({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    }
                    </>):null}

                </div>
                <Dialog

        open={openPickUpForm}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            minHeight: "400px",
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <PickupDispositionFormContainer
            onClose={handleCloseModal}
            editDataSendToModal={editDataSendToModal}
            isOrderScreen={true}
            isEditScreen={true}
            handleAddPickupDispo={handleAddPickupDispo}

          />
        </DialogContent>
      </Dialog>

            </Paper >
        </div >
        ) : (
            <div className="mainContainer width_100" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Divider style={{ flex: 1, marginRight: '8px' }} >
                        <Chip label="Pickup/Return Details" size="small" variant="outlined" />
                    </Divider>
                </div>

                <Paper className="paperstyl1" variant="outlined" style={{ flex: 1, display: 'flex', flexDirection: 'column' }} variant="outlined">
                <div style={{ width: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? "100%" : '100%', display: 'flex', flexDirection: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? 'row' : 'row', }}>

                    <div className="pickinguprow" style={{ display: 'flex', flexDirection: 'column', width: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? isTemplateScreen?"100%":'33.33%' : '50%' }} >
                    <div className={isTemplateScreen &&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') ?"pickinguprow":null} style={isTemplateScreen&&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT')?{ display: "flex", flexDirection: 'row' }:null} >
                        <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                        <Autocomplete
                                disablePortal
                                id="pickup-point-select"
                                size="small"
                                value={pickupPointSelect}
                                options={orderReducer.pickupPointLookupRes}
                                getOptionLabel={(option) => `${option.code} ${option.name}`}
                                className="width_ 100"
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                            {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                        </li>
                                    )
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} value={params.code} label="Pickup Point"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') && isEdiScreen && !pickupPointSelect ? 'red' : null,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') && isEdiScreen && !pickupPointSelect ? 'red' : null,
                                        },
                                    }
                                }}
                                />}
                                onInputChange={handlePickupPointLookup}
                                onChange={handlePickupPointChange}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 500
                                        }
                                    }
                                }}
                                sx={
                                    (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') ? {
                                        input: {
                                            background: isTemplateScreen?null:"#F8F0E3",
                                        }
                                    } : {}
                                }
                                disabled={disableFull}
                            />

                            </div>
                            <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       {/* { pickupPointSelect?<EditIcon/>: <AddIcon/>} */}
                       { pickupPointSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(pickupPointSelect,"PickUp","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(pickupPointSelect,"PickUp","Add")}}/>}

                      </IconButton>
                      {((selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT')  && isEdiScreen)?
                      (
                      <Tooltip
                        PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={open}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        //   title={"valueToMatch"}
                        title={<span style={{ fontSize: '16px', padding: '10px' }}>{`${billingControlRef?.current?.selectedStop?.name},${billingControlRef?.current?.selectedStop?.address},${billingControlRef?.current?.selectedStop?.city},${billingControlRef?.current?.selectedStop?.state}`}</span>}
                        sx={{
                          tooltip: {
                            maxWidth: 'none', // Removes the default max-width
                            backgroundColor: '#f5f5f9',
                            color: 'rgba(0, 0, 0, 0.87)',
                            boxShadow: 1,
                            fontSize: '14px',
                            padding: '10px',
                            border: '1px solid #dadde9',
                          },
                        }}
                        >
                          <IconButton onClick={handleButtonClick} disabled={disableFull}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>):null}
                      </div>

                        </div>
                        <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                            <Autocomplete
                                disablePortal
                                id="disposition-select"
                                size="small"
                                value={dispositionSelect}
                                options={orderReducer.dispositionLookupRes}
                                getOptionLabel={(option) => `${option.code} ${option.name}`}
                                className="width_ 100 "
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                            {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                        </li>
                                    )
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} value={params.code} label="Disposition"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') && isEdiScreen &&!dispositionSelect ? 'red' : null,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') && isEdiScreen && !dispositionSelect ? 'red' : null,
                                        },
                                    }
                                }}

                                />}
                                onInputChange={handleDispositionLookup}
                                onChange={handleDispositionChange}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 500
                                        }
                                    }
                                }}
                                sx={
                                    (selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') ? {
                                        input: {
                                            background:isTemplateScreen?null:"#F8F0E3",
                                        }
                                    } : {}
                                }
                                disabled={disableFull}
                            />

                            </div>
                            <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       { dispositionSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(dispositionSelect,"Disposition","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(dispositionSelect,"Disposition","Add")}}/>}
                      </IconButton>
                     {((selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') && isEdiScreen)?
                        (<Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            //   title={"valueToMatch"}
                            title={<span style={{ fontSize: '16px', padding: '10px' }}>{`${billingControlRef?.current?.selectedStop?.name},${billingControlRef?.current?.selectedStop?.address},${billingControlRef?.current?.selectedStop?.city},${billingControlRef?.current?.selectedStop?.state}`}</span>}
                            sx={{
                            tooltip: {
                             maxWidth: 'none', // Removes the default max-width
                             backgroundColor: '#f5f5f9',
                             color: 'rgba(0, 0, 0, 0.87)',
                             boxShadow: 1,
                             fontSize: '14px',
                             padding: '10px',
                             border: '1px solid #dadde9',
                            },
                            }}
                            >
                              <IconButton onClick={handleButtonClick} disabled={disableFull}>
                                <InfoIcon />
                              </IconButton>
                        </Tooltip>):null}
                      </div>
                        </div>
                        </div>
                        {
                            (selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') &&

                            (<>
                            <div className={isTemplateScreen &&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') ?"pickinguprow":null} style={isTemplateScreen&&(selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT')?{ display: "flex", flexDirection: 'row' }:null} >

                                <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="chassis-pickup-select"
                                        size="small"
                                        value={chassisPickupSelect}
                                        className="width_ 100"
                                        options={orderReducer.chassisPickupLookupRes}
                                        getOptionLabel={(option) => `${option.code} ${option.name}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                    {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                </li>
                                            )
                                        }}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} value={params.code} label="Chassis Pickup" />}
                                        onInputChange={handleChassisPickupLookup}
                                        onChange={handleChassisPickupChange}
                                        disabled={disableFull}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    width: 500
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                 <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       {/* { chassisPickupSelect?<EditIcon/>: <AddIcon/>} */}
                       { chassisPickupSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(chassisPickupSelect,"Chassis Pickup","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(chassisPickupSelect,"Chassis Pickup","Add")}}/>}

                      </IconButton>

                      </div>
                                </div>
                                <div className="width_99 paddingright" style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ flex: '0 0 80%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="chassis-disposition-select"
                                        size="small"
                                        value={chassisDispositionSelect}
                                        className="width_ 100"
                                        options={orderReducer.chassisDispositionLookupRes}
                                        getOptionLabel={(option) => `${option.code} ${option.name}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                    {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                </li>
                                            )
                                        }}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} value={params.code} label="Chassis Disposition" />}
                                        onInputChange={handleChassisDispositionLookup}
                                        onChange={handleChassisDispositionChange}
                                        disabled={disableFull}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    width: 500
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                        onClick={handleAddEditPickupClick}
                        disabled={disableFull}
                      >
                       { chassisDispositionSelect?<EditIcon onClick={()=>{handleAddEdiitModalData(chassisDispositionSelect,"Chassis Disposition","Edit")}}/>: <AddIcon onClick={()=>{handleAddEdiitModalData(chassisDispositionSelect,"Chassis Disposition","Add")}}/>}
                      </IconButton></div>
                                </div>
                                </div>


                            </>)
                        }
                        {
                            (selectedOrderType === 'INBOUND') &&
                            (<>
                                <div className="width_99 paddingright" >
                                    <CustomDateTimePicker
                                        label="ETA Date & Time"
                                        size="small"
                                        className="width_100"
                                        value={`${pickupDetailState.etaDate}T${pickupDetailState.etaTime}` || ''}
                                        onValueChange={(timestamp) => {
                                            setPickupDetailState({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime });
                                            updateBillingControlRef({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime })
                                        }}
                                        disabled={disableFull}
                                    />
                                </div>

                            </>

                            )}
                        {
                            (selectedOrderType === 'INBOUND' || selectedOrderType === 'OUTBOUND') &&
                            (<>
                                <div className="width_99 paddingright" >
                                    <CustomDateTimePicker
                                        label={(selectedOrderType === 'EXPORT' || selectedOrderType === 'OUTBOUND') ? "Cutt Off Date & Time" : "Last Storage Date & Time"}
                                        size="small"
                                        className="width_100"
                                        value={`${pickupDetailState.lastFreeDay}T${pickupDetailState.lastFreeTime}` || ''}
                                        onValueChange={(timestamp) => {
                                            setPickupDetailState({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                            updateBillingControlRef({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime })
                                        }}
                                        disabled={disableFull}
                                        sx={
                                            (selectedOrderType === 'EXPORT') ? {
                                                input: {
                                                    background: "#F8F0E3"
                                                }
                                            } : {}
                                        }
                                    />
                                </div>

                            </>)
                        }

                    </div>


                  {!isTemplateScreen?(<>
                    <div className="pickinguprow width_100" style={{ display: 'flex', flexDirection: 'column', width: (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? '33.33%' : '50%' }} >
                        <div className="width_99 paddingleft" >
                            <CustomDateTimePicker
                                label="Pickup Date & Time"
                                size="small"
                                className="width_100"
                                value={`${pickupDetailState.pickupDate}T${pickupDetailState.pickupTime}` || ''}
                                onValueChange={(timestamp) => {
                                    setPickupDetailState({ ...pickupDetailState, pickupDate: timestamp.eDate, pickupTime: timestamp.eTime });
                                    updateBillingControlRef({ ...pickupDetailState, pickupDate: timestamp.eDate, pickupTime: timestamp.eTime })
                                }}
                                disabled={disableFull}
                            />

                        </div>
                        <div className="width_99 paddingleft" >
                            <CustomDateTimePicker
                                label="Return Date & Time"
                                size="small"
                                className="width_100"
                                value={`${pickupDetailState.returnDate}T${pickupDetailState.returnTime}` || ''}
                                onValueChange={(timestamp) => {
                                    setPickupDetailState({ ...pickupDetailState, returnDate: timestamp.eDate, returnTime: timestamp.eTime });
                                    updateBillingControlRef({ ...pickupDetailState, returnDate: timestamp.eDate, returnTime: timestamp.eTime })
                                }}
                                disabled={disableFull}
                            />
                        </div>

                        {
                            (selectedOrderType === 'EXPORT' || selectedOrderType === 'IMPORT') &&

                            (<>

                                <div className="width_99 paddingleft" >
                                    <CustomDateTimePicker
                                        label="Pickup Date & Time"
                                        size="small"
                                        className="width_100"
                                        value={''}
                                        // onValueChange={(timestamp) => {}}
                                        disabled={true}
                                    />
                                </div>

                                <div className="width_99 paddingleft" >
                                    <CustomDateTimePicker
                                        label="Return Date & Time"
                                        size="small"
                                        className="width_100"
                                        value={''}
                                        // onValueChange={(timestamp) => {}}
                                        disabled={true}
                                    />

                                </div>

                            </>)
                        }
                        {
                            (selectedOrderType === 'INBOUND') &&
                            (
                                <div className="width_99 paddingleft" >
                                    <CustomDateTimePicker
                                        label={selectedOrderType === 'EXPORT' ? "ERD Date & Time" : "Notify Date & Time"}
                                        size="small"
                                        className="width_100"
                                        value={`${pickupDetailState.notifyDate}T${pickupDetailState.notifyTime}` || ''}
                                        onValueChange={(timestamp) => {
                                            setPickupDetailState({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                            updateBillingControlRef({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime })
                                        }}
                                        disabled={disableFull}
                                        sx={
                                            (selectedOrderType === 'EXPORT') ? {
                                                input: {
                                                    background: "#F8F0E3"
                                                }
                                            } : {}
                                        }
                                    />
                                </div>
                            )
                        }
                        {
                            (selectedOrderType === 'INBOUND' || selectedOrderType === 'OUTBOUND') &&
                            (<>
                                <div className="width_99 paddingleft" >

                                    <CustomDatePicker
                                        label="Last/Diem day"
                                        size="small"
                                        className="width_100"
                                        value={pickupDetailState.lastPerDiemDays || ''}
                                        onValueChange={(selectedVal) => {
                                            setPickupDetailState({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                            updateBillingControlRef({ ...pickupDetailState, lastPerDiemDays: selectedVal })
                                        }}
                                        disabled={disableFull}
                                    />
                                </div>
                            </>)

                        }


                    </div>
                    {/* ):null} */}

                    {
                        (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') &&
                        (
                            <div className="pickinguprow" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '0.2rem', width: '33.33%' }}>

                                {/* For IMPORT */}
                                {selectedOrderType === 'IMPORT' && (
                                    <>
                                        {/* ETA Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="ETA Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.etaDate}T${pickupDetailState.etaTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, etaDate: timestamp.eDate, etaTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Notify Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="Notify Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.notifyDate}T${pickupDetailState.notifyTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Last Storage Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="Last Storage Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.lastFreeDay}T${pickupDetailState.lastFreeTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Last/Diem Day */}
                                        <div className="width_99 paddingleft">
                                            <CustomDatePicker
                                                label="Last/Diem Day"
                                                size="small"
                                                className="width_100"
                                                value={pickupDetailState.lastPerDiemDays || ''}
                                                onValueChange={(selectedVal) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                    updateBillingControlRef({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>
                                    </>
                                )}

                                {/* For EXPORT */}
                                {selectedOrderType === 'EXPORT' && (
                                    <>
                                        {/* ERD Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="ERD Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.notifyDate}T${pickupDetailState.notifyTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, notifyDate: timestamp.eDate, notifyTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Cut Off Date & Time */}
                                        <div className="width_99 paddingleft">
                                            <CustomDateTimePicker
                                                label="Cut Off Date & Time"
                                                size="small"
                                                className="width_100"
                                                value={`${pickupDetailState.lastFreeDay}T${pickupDetailState.lastFreeTime}` || ''}
                                                onValueChange={(timestamp) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                    updateBillingControlRef({ ...pickupDetailState, lastFreeDay: timestamp.eDate, lastFreeTime: timestamp.eTime });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>

                                        {/* Last/Diem Day */}
                                        <div className="width_99 paddingleft">
                                            <CustomDatePicker
                                                label="Last/Diem Day"
                                                size="small"
                                                className="width_100"
                                                value={pickupDetailState.lastPerDiemDays || ''}
                                                onValueChange={(selectedVal) => {
                                                    setPickupDetailState({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                    updateBillingControlRef({ ...pickupDetailState, lastPerDiemDays: selectedVal });
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    }
                    </>):null}

                </div>
                <Dialog

                    open={openPickUpForm}
                    onClose={handleCloseModal}
                    maxWidth="md"
                    fullWidth
                    PaperProps={{
                    style: {
                        minHeight: "400px",
                    },
                    }}
                >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                    <PickupDispositionFormContainer
                        onClose={handleCloseModal}
                        editDataSendToModal={editDataSendToModal}
                        isOrderScreen={true}
                        isEditScreen={true}
                        handleAddPickupDispo={handleAddPickupDispo}

                    />
                    </DialogContent>
                </Dialog>
            </Paper >
            </div >
        ))
    );
}