import { dispatchTypes } from "../types/dispatchTypes"
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk'
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import moment from 'moment';

export const getTrucksData = (LocationNumber: any) => {
    return async (dispatch: Dispatch) => {
        const res = await getTrucksDataReq(LocationNumber);
        dispatch(setTrucks(res));
        dispatch(setReloadTableState(false));
        return res;
    }
}

export const getTrucksDataReq = async (locationNumber: number) => {
    try {
        let res = await GET(`trucks/active/${locationNumber}`);
        return res;
    } catch (err) {
        return [];
    }
}

const setTrucks = (apiData: any) => {
    return {
        type: dispatchTypes.ACTIVE_TRUCKS,
        payload: apiData,
    };
};

export const getTrucksSubItemData = (postData: any, locationNumber: number) => {
    return async (dispatch: Dispatch) => {
        const res = await stopSearchApiReq(postData, locationNumber, 'driver');
        dispatch(setTrucksSubItems(res));
        return res;
    };
}

const setTrucksSubItems = (apiData: any) => {
    return {
        type: dispatchTypes.SUBITEM_DATA,
        payload: apiData,
    };
};

export const getLoadsData = (postData: any, locationNumber: number) => {
    return async (dispatch: Dispatch) => {
        const res = await stopSearchApiReq(postData, locationNumber, 'loads');
        dispatch(setLoad(res));
        return res;
    };
}

const setLoad = (apiData: any) => {
    return {
        type: dispatchTypes.LOADS_DATA,
        payload: apiData,
    };
};

export const getSpottedData = (postData: any, locationNumber: number) => {
    return async (dispatch: Dispatch) => {
        const res = await stopSearchApiReq(postData, locationNumber, 'spotted');
        dispatch(setSpotted(res));
        return res;
    };
}

const setSpotted = (apiData: any) => {
    return {
        type: dispatchTypes.SPOTTED_DATA,
        payload: apiData,
    };
};

export const getStopsData = (postData: any, locationNumber: number, requestType: string) => {
    return async (dispatch: Dispatch) => {
        const res = await stopSearchApiReq(postData, locationNumber, requestType);
        dispatch(setStops(res));
        return res;
    };
}
export const setStops = (apiData: any) => {
    return {
        type: dispatchTypes.ALL_STOPS,
        payload: apiData,
    };
};
export const stopSearchApiReq = async (postData: any, locationNumber: number, requestType: string) => {
    try {
        let res = await POST(`/elastic/stops/_search?page=1&pageSize=10000&reqType=${requestType}`, postData);
        if (res && res.httpCode === 200 && res.status === 'success') {
            res = mapStopsDataToClient(res.data.hits);
            return res;
        }
        return [];
    } catch (err) {
        return [];
    }
}
export const stopSearchWSReq = (res: any) => {
    // try {
    //     console.log(res.httpCode);
    if (res && res.httpCode === 200 && res.status === 'success') {
        const res2 = mapStopsDataToClient(res.data.hits);
        return res2;
    }
    //     return [];
    // } catch (err) {
    //     return [];
    // }
}
export const reorderLoadsReq = async (postData: any, locationNumber: number) => {
    try {
        let res = await POST(`/dispatch-board/display-position/${locationNumber}`, postData);
        if (res && res.httpCode === 200 && res.status === 'success') {
            return res;
        }
        return [];
    } catch (err) {
        return [];
    }
}

const mapStopsDataToClient = (stopData: any) => {

    let finalData: any = [];

    stopData?.map((item: any, indx: number) => {
        let obj = item._source;
        //if (!['V', 'C', 'B', 'M'].includes(obj.status) && obj['stops.departTruckStatus'] !== 'C') {
        if (!['V', 'C', 'B', 'M'].includes(obj.status) && obj.billingDate === '') { //  && obj['stops.departTruckStatus'] !== 'C'
            let splitStopNumber = obj.stopNumber.split('/');
            finalData.push({
                id: indx,
                stop: obj.stopNumber,
                io: (obj.orderType === 'DOMINB' || obj.orderType === 'IMPORT') ? 'I' : (obj.orderType === 'DOMOUT' || obj.orderType === 'EXPORT') ? 'O' : '',
                control: obj.controlNumber,
                spec: getSpec(obj),
                unit: obj?.unitNumber || '',
                ssl: (obj.orderType === 'DOMINB' || obj.orderType === 'DOMOUT') ? '' : (obj.orderType === 'IMPORT' || obj.orderType === 'EXPORT') ? (obj.orderType === 'EXPORT' || obj.orderType === 'DOMOUT') ? obj?.shipperOrConsignee : (obj.orderType === 'IMPORT' || obj.orderType === 'DOMINB') ? obj?.shipperOrConsShipper || '' : '' || '' : '',
                size: obj.equipSize,
                puloc: obj?.poruPoint || '',
                rtnloc: obj?.disposition || '',
                lfd: obj.cuttOffDate,
                shipConsName: obj['stops.name'] || "", // (obj.orderType === 'EXPORT' || obj.orderType === 'DOMOUT') ? obj?.shipper : (obj.orderType === 'IMPORT' || obj.orderType === 'DOMINB') ? obj?.consignee || '' : '', //obj?.consignee || '',
                cityState: `${obj['stops.city'] + ', ' + obj['stops.state']}`,
                isPastStop: pastApptStops(getDate(obj['stops.apptDate'])),
                apptDate: obj['stops.apptDate'] || '',
                apptTime: obj['stops.apptTime'] || '',
                apptDateTime: `${getDate(obj['stops.apptDate']) + ' ' + obj['stops.apptTime']}`,
                apptDateForSort: getDate(obj['stops.apptDate']) || '',
                apptTimeForSort: obj['stops.apptTime'].replace(/^0+/, ''), //getTime(obj['stops.apptTime']) || '',
                release: (obj.orderType === 'DOMINB' || obj.orderType === 'IMPORT' || obj.orderType === 'DOMOUT') ? `${obj.releaseNumber}` : (obj.orderType === 'EXPORT') ? `${obj.billingBooking}` : '', //obj?.releaseNumber || '',
                dispatchType: '',
                outageDateTime: `${getDate(obj.poruDate) + ' ' + getTime(obj.poruTime)}`,
                spotDate: obj['stops.spotDate'] || 0,
                spotDateTime: `${getDate(obj['stops.spotDate']) + ' ' + getTime(obj['stops.spotTime'])}`,
                availDate: obj['stops.availDate'] || 0,
                availDateTime: `${getDate(obj['stops.availDate']) + ' ' + getTime(obj['stops.availTime'])}`,
                lastFreeDay: `${getDate(obj.pickDate)}`,
                locationNumber: obj.locationNumber,
                lineNumber: obj['stops.lineNumber'],
                arriveTruckStatus: obj['stops.arriveTruckStatus'] || '',
                departTruckStatus: obj['stops.departTruckStatus'] || '',
                arriveTruck: obj['stops.arriveTruck'] || 0,
                departTruck: obj['stops.departTruck'] || 0,
                arriveDriver: obj['stops.arriveDriver'] || 0,
                departDriver: obj['stops.departDriver'] || 0,
                lfdcut: `${getDate(obj.cuttOffDate) + ' ' + getTime(obj.cutOffTime)}`,
                isLfdCutOld: (getDate(obj.cuttOffDate) < getDate(obj['stops.apptDate'])) ? 'yes' : 'no',
                changedDate: getLastEventDate(obj, 'changeDate'), //getDate(obj.changedDate)
                tenderType: obj['stops.tenderType'] || '',
                displayPosition: obj['stops.displayPosition'],
                lastEvent: getLastEventDate(obj, 'lastEvent'),
                stopName: obj['stops.name'] || '',
                customer: obj?.customerNumber || 0,
                shipperAgentCode: obj?.shipperAgentCode || '',
                shipperConsCode: obj?.shipperOrConsignee || '',
                stopsCount: splitStopNumber[1],
                etaDateTime: `${getDate(obj?.etaDate) + ' ' + getTime(obj?.etaTime)}`,
                chassisNumber: obj?.chassisNumber || '',
                notifyDateTime: `${getDate(obj.notifyDate) + ' ' + getTime(obj.notifyTime)}`,
            });
        }
        //}
    });
    // let sortedData = finalData.sort((a: any, b: any) => {
    //     let dateB: any = new Date(b.apptDate);
    //     let dateA: any = new Date(a.apptDate);
    //     return dateB - dateA;
    // });
    return finalData;
}
const getSpec = (obj: any) => {
    if (obj?.hazMat === 'Y') {
        return 'HAZMAT';
    } else if (obj?.tanker === 'Y') {
        return 'TANKER';
    } else if (obj?.overWeight === 'Y') {
        return 'OW';
    } else if (obj?.reefer === 'Y') {
        return 'REEFER';
    } else if (obj?.residential === 'Y') {
        return 'REZ';
    } else {
        return "";
    }
}
const getDate = (dateVal: any) => {
    if (dateVal === 0 || !dateVal)
        return 0
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return moment(date).format('MM/DD/YY');
    }
    return date;
}
const getTime = (timeVal: any) => {
    if (!timeVal || timeVal === 0)
        return ""
    if (timeVal.toString().length === 3)
        timeVal = `0${timeVal.toString().substring(0, 1)}:${timeVal.toString().substring(1)}`
    else
        timeVal = timeVal; //`${timeVal.toString().substring(0, 2)}:${timeVal.toString().substring(2)}`
    return timeVal;
}

const getCovertedTime = (timeVal: any) => {
    if (!timeVal || timeVal === 0)
        return ""
    if (timeVal.toString().length === 3)
        timeVal = `0${timeVal.toString().substring(0, 1)}:${timeVal.toString().substring(1)}`
    else
        timeVal = `${timeVal.toString().substring(0, 2)}:${timeVal.toString().substring(2)}`
    return timeVal;
}

const pastApptStops = (apptDate: any) => {
    if (typeof apptDate !== 'undefined' && apptDate !== '' && apptDate !== null) {

        const now = moment().format('MM/DD/YY');
        const dataDiffD = moment(apptDate).isAfter(now, 'day');
        if (!dataDiffD) {
            return 'yes';
        } else {
            return 'no';
        }
    } else {
        return 'no';
    }
}

const getLastEventDate = (obj: any, type: string) => {
    let datesArr = [
        {
            key: 'Assigned',
            value: (obj['stops.arriveTruckStatus'] === 'A' || obj['stops.departTruckStatus'] === 'A') ? (!obj['stops.tenderDate']) ? 0 : new Date(`${getDate(obj['stops.tenderDate']) + ' ' + getCovertedTime(obj['stops.tenderTime'])}`) : 0
        },
        {
            key: 'Tender',
            value: (obj['stops.arriveTruckStatus'] === 'T' || obj['stops.departTruckStatus'] === 'T') ? (!obj['stops.tenderDate']) ? 0 : new Date(`${getDate(obj['stops.tenderDate']) + ' ' + getCovertedTime(obj['stops.tenderTime'])}`) : 0 // stops.tenderDate,
        },
        {
            key: 'Accepted',
            value: (!obj['stops.tenderAcceptedDate']) ? 0 : new Date(`${getDate(obj['stops.tenderAcceptedDate']) + ' ' + getCovertedTime(obj['stops.tenderAcceptedTime'])}`) // stops.tenderAcceptedDate,
        },
        {
            key: 'Rejected',
            value: (!obj['stops.tenderRejectedDate']) ? 0 : new Date(`${getDate(obj['stops.tenderRejectedDate']) + ' ' + getCovertedTime(obj['stops.tenderRejectedTime'])}`) // stops.tenderRejectedDate,
        },
        {
            key: 'Outgate',
            value: (!obj.poruDate) ? 0 : new Date(`${getDate(obj.poruDate) + ' ' + getCovertedTime(obj.poruTime)}`) //poruDate,
        },
        {
            key: 'ETA',
            value: (!obj['stops.etaDate']) ? 0 : new Date(`${getDate(obj['stops.etaDate']) + ' ' + getCovertedTime(obj['stops.etaTime'])}`) // stops.etaDate,
        },
        {
            key: 'Arrive',
            value: (!obj['stops.arriveDate']) ? 0 : new Date(`${getDate(obj['stops.arriveDate']) + ' ' + getCovertedTime(obj['stops.arriveTime'])}`) //stops.arriveDate,
        },
        {
            key: 'Spot',
            value: (!obj['stops.spotDate']) ? 0 : new Date(`${getDate(obj['stops.spotDate']) + ' ' + getCovertedTime(obj['stops.spotTime'])}`) // stops.spotDate
        },
        {
            key: 'Load',
            value: 0,
        },
        {
            key: 'Unload',
            value: (!obj['stops.unloadDate']) ? 0 : new Date(`${getDate(obj['stops.unloadDate']) + ' ' + getCovertedTime(obj['stops.unloadTime'])}`) // stops.unloadDate,
        },
        {
            key: 'Depart',
            value: (!obj['stops.departDate']) ? 0 : new Date(`${getDate(obj['stops.departDate']) + ' ' + getCovertedTime(obj['stops.departTime'])}`) // stops.departDate,
        },
        {
            key: 'Return Date',
            value: (!obj.returnDate) ? 0 : new Date(`${getDate(obj.returnDate) + ' ' + getCovertedTime(obj.returnTime)}`) // returnDate
        }
    ];

    const maxDate: any = datesArr.filter(e => e.value !== 0).reduce((a, b) => {
        if (a > b) {
            return a;
        } else {
            return b
        }
    }, {});
    if (typeof maxDate.key === 'undefined') {
        return 'NA';
    } else if (type === 'lastEvent') {
        return `${maxDate.key} (${moment(maxDate.value).format('MM/DD/YY HH:mm')})`;
    } else {
        return `${moment(maxDate.value).format('YYYY-MM-DD HH:mm')}`;
    }
}

export const reloadTableData = (value: boolean) => {
    return async (dispatch: Dispatch) => {
        dispatch(setReloadTableState(value));
    };
}
export const setReloadTableState = (apiData: boolean) => {
    return {
        type: dispatchTypes.RELOAD_TABLES_ON_TENDER,
        payload: apiData,
    };
};



