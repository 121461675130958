import React, { useContext } from "react";
import { TextField, Paper, IconButton, Autocomplete, Button, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import TenderModalNew from "../../../components/Tender/Modal/TenderModalNew";

import './Styles.css'

import { useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { GET, POST, PUT } from '../../../utils/axios'
import { useParams } from "react-router-dom";

import { PhoneNumberMask } from '../../../components/TextFieldFormat';
import orderContext from '../orderContext';

// import custom date-time picker
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
// import custom date picker
import CustomDatePicker from '../../../components/CustomDatePicker';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ShipperConsigneeFormContainer from "../../ShipperConsignee/ShipperConsigneeFormContainer";

import {
    getPOD, unTenderType
} from "../../../store/actions/loadsActions";
import { WindowSharp } from "@mui/icons-material";
import {checkNonMatchingFields} from "../../EdiOrder/components/commonFn"
import CachedIcon from '@mui/icons-material/Cached';


const GridContainer = (props) => (
    <Grid container   {...props.gridProps}>
        {props.children}
    </Grid>
)

const GridItem = (props) => (
    <Grid item {...props.gridProps} >
        {props.children}
    </Grid>
)
export default function StopsForm(props) {

    const { stop, stopsRef, handleDeleteStop, selectedOrderType, handleAddStop, disableFull, isTemplateScreen, controlNumber, locationNumber, canEnterTruckDetailsDepart, canEnterTruckDetailsArrive,setStopsList, allDrivers,nonMatchingFields,isEdiScreen, setStopsUpdate } = props
    let params = useParams();
    const dispatch = useDispatch();
    const screenStatus = useSelector(({ loadsReducer }) => {
        return loadsReducer.screenStatus
    });
    const driverList = useSelector(({ loadsReducer }) => {
        return loadsReducer.driverList
    });
    const [orientation, setOrientation] = useState(screenStatus ? 'vertical' : window.innerWidth < 1400 ? 'vertical' : 'horizontal')

    const [stopState, setStopState] = useState({
        ...stop
    })
    const [addEditShipper, setAddEditShipper] = useState(null)
    const [addEditShipperMode, setAddEditShipperMode] = useState(false);
    const [newShipper, setNewShipper] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [tenderModal, setTenderModal] = useState(false)
    const [selectedStops, setSelectedStops] = useState([])

    const [shipperSelect, setShipperSelect] = useState(stop.consignee ? { code: stop.consignee, name: stop.name } : null)
    const [shipperOptions, setShipperOptions] = useState([])

    const [arriveTruckSelect, setArriveTruckSelect] = useState(stop.arriveTruck ? { truck: stop.arriveTruck } : null)
    const [arriveTruckOptions, setArriveTruckOptions] = useState([])

    const [arriveDriverSelect, setArriveDriverSelect] = useState(stop.arriveDriver ? { id: stop.arriveDriver } : null)
    const [arriveDriverOptions, setArriveDriverOptions] = useState([])

    const [departTruckSelect, setDepartTruckSelect] = useState(stop.departTruck ? { truck: stop.departTruck } : null)
    const [departTruckOptions, setDepartTruckOptions] = useState([])

    const [departDriverSelect, setDepartDriverSelect] = useState(stop.departDriver ? { id: stop.departDriver } : null)
    const [departDriverOptions, setDepartDriverOptions] = useState([])
    const [isTextField, setIsTextField] = useState(false);

    const { setIsStateChanged } = useContext(orderContext);

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );
    params = params?.locationNumber ? params : { locationNumber: ordersLocation.locationNumber, controlNumber: '' }
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('stop form fields are changed')
        setIsStateChanged(true);
        /** END */
    }

    const handleClose = (event) => {
        setSnackOpen(false);
    };

    useEffect(() => {
        console.log('Updating Stop because the component rerender')
        setArriveTruckSelect(stop.arriveTruck ? { truck: stop.arriveTruck } : null)
        setDepartTruckSelect(stop.departTruck ? { truck: stop.departTruck } : null)
        setShipperSelect(stop.consignee ? { code: stop.consignee, name: stop.name } : null)
        setStopState({ ...stop })
        if (driverList.length > 0) {
            getActiveTrucks(driverList);
        }
    }, [stop, driverList])

    const closeModal = useCallback(() => {
        setTenderModal(false);
    }, [setTenderModal]);

    const tenderSelectStops = useCallback(
        (stopNumber) => {
            setSelectedStops(
                stopNumber !== undefined ? [stopNumber] : []
            );
            setTenderModal(true);
        },
        [setSelectedStops]
    );



    const updateStops = (updatedStop) => {
        const stopsList = stopsRef.current.filter(item => item.newStop !== updatedStop.newStop)
        stopsRef.current = [...stopsList, updatedStop]
        // setStopsList(...stopsList, updatedStop)
        handleFieldChanges()
    }

    const clearAutocomplete = () => {
        setShipperSelect(null);
        setAddEditShipperMode(false);

        setStopState({
            ...stopState,
            consignee: "",
            name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            contactPhone: "",
            fax: "",
            yard: "",
            note1: "",
            note2: "",
            note3: "",
            contactName: "",
            contactEmail: "",
            internalNotes: "",
            defaultContact: ""
        })
        // Update parent here
        updateStops({
            ...stopState,
            consignee: "",
            name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            contactPhone: "",
            fax: "",
            yard: "",
            note1: "",
            note2: "",
            note3: "",
            contactName: "",
            contactEmail: "",
            internalNotes: "",
            defaultContact: ""
        })
    }
    const handleShipperChange = (event, value, reason) => {
        console.log('Selected Shipper', value)
        if (reason === "clear") {
            clearAutocomplete();
            return false;
        }

        setShipperSelect(value);
        setAddEditShipperMode(true);

        setStopState({
            ...stopState,
            consignee: value.code,
            name: value.name,
            address: value.address,
            city: value.city,
            state: value.state,
            zip: value.zip,
            contactPhone: value.phone,
            fax: value.fax,
            //yard: value.yard,
            yard: 'N',
            note1: value.note1,
            note2: value.note2,
            note3: value.note3,
            contactName: `${value.firstName || ''} ${value.lastName || ''}`,
            contactEmail: value.email || '',
            internalNotes: value.note1,
            defaultContact:value?.defaultContact || ''
        })
        // Update parent here
        updateStops({
            ...stopState,
            consignee: value.code,
            name: value.name,
            address: value.address,
            city: value.city,
            state: value.state,
            zip: value.zip,
            contactPhone: value.phone,
            fax: value.fax,
            //yard: value.yard,
            yard: 'N',
            note1: value.note1,
            note2: value.note2,
            note3: value.note3,
            contactName: `${value.fristName || ''} ${value.lastName || ''}`,
            contactEmail: value.email || '',
            internalNotes: value.note1,
            defaultContact:value?.defaultContact || ''
        })
    }

    // Lookup handlers
    const shipperLookupTimeout = useRef(null)
    const arriveTruckLookupTimeout = useRef(null)
    const arriveDriverLookupTimeout = useRef(null)
    const departTruckLookupTimeout = useRef(null)
    const departDriverLookupTimeout = useRef(null)

    const handleShipperLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (shipperLookupTimeout.current)
                clearTimeout(shipperLookupTimeout.current)

            const timer = setTimeout(async () => {
                const response = await GET(`/shippers/${params?.locationNumber}?searchVal=${encodeURIComponent(value)}`)
                if (response.status !== 'error')
                    setShipperOptions(response.data)
                else
                    setShipperOptions([])
                console.log(response)
            }, 1000)

            shipperLookupTimeout.current = timer
        }
        else {
            if (value === "") {
                setAddEditShipperMode(false);
            }
            console.log('Reason is reset not making an api call')
        }
    }

    const handleArriveTruckLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (arriveTruckLookupTimeout.current)
                clearTimeout(arriveTruckLookupTimeout.current)

            const timer = setTimeout(async () => {
                const response = await GET(`/trucks?searchVal=${value}&location=${params?.locationNumber}`)
                console.log(response)
                if (response.status !== 'error')
                    setArriveTruckOptions(response)
                else
                    setArriveTruckOptions([])
            }, 1000)

            arriveTruckLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    const handleDepartTruckLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (departTruckLookupTimeout.current)
                clearTimeout(departTruckLookupTimeout.current)

            const timer = setTimeout(async () => {
                const response = await GET(`/trucks?searchVal=${value}&location=${params?.locationNumber}`)
                if (response.status !== 'error')
                    setDepartTruckOptions(response)
                else
                    setDepartTruckOptions([])
                console.log(response)
            }, 1000)

            departTruckLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    const getActiveTrucks = (driverList) => {

        const response = driverList.filter(e => e.isAvailable === 'Y').map(e1 => {
            return {
                driverId: e1.id,
                name: e1.name,
                truck: e1.truck
            }
        })

        if (response.length > 0) {
            setArriveTruckOptions(response)
            setDepartTruckOptions(response)
        }
    }

    const handleArriveDriverLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (arriveDriverLookupTimeout.current)
                clearTimeout(arriveDriverLookupTimeout.current)

            const timer = setTimeout(async () => {
                const response = await GET(`/drivers?searchVal=${value}`)
                if (response.status !== 'error')
                    setArriveDriverOptions(response)
                else
                    setArriveDriverOptions([])
                console.log(response)
            }, 1000)

            arriveDriverLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    const handleDepartDriverLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (departDriverLookupTimeout.current)
                clearTimeout(departDriverLookupTimeout.current)

            const timer = setTimeout(async () => {
                const response = await GET(`/drivers?searchVal=${value}`)
                if (response.status !== 'error')
                    setDepartDriverOptions(response)
                else
                    setDepartDriverOptions([])
                console.log(response)
            }, 1000)

            departDriverLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }


    const handleAddEditShipperClick = (event) => {
        console.log('Add Edit Clicked Current', addEditShipper)
        if (addEditShipperMode) {
            setAddEditShipper('Add')
            setNewShipper({ ...stopState })
            return false;
        }
        setNewShipper({
            consignee: '',
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            contactPhone: '',
            fax: '',
            yard: '',
            note1: '',
            note2: '',
            note3: ''
            
        })
        setAddEditShipper("Add")

        // if (shipperSelect !== null && shipperSelect !== "")
        //     setAddEditShipper(shipperSelect.code)
        // else if ((shipperSelect === null || shipperSelect === "") && addEditShipper !== 'Add')
        //     setAddEditShipper("Add")
        // else if (addEditShipper)
        //     setAddEditShipper(null)
        // console.log('Add Edit Clicked', shipperSelect)
    }

    function extractPrimaryContact(data) {
        // Find the primary contact
        const primaryContact = data.find(contact => contact.primary === "Y");
    
        // If primary contact exists, extract required fields
        if (primaryContact) {
            const { contact_name,phone_number, email } = primaryContact;
            return [{ contact_name, phone_number, email }];
        } else {
            return [];
        }
    }

    const handleAddShipper = (data,contactData) => {
        const primaryContactData = extractPrimaryContact(contactData);
        console.log("primaryContactData",primaryContactData);
        console.log("shipperdata",data);
        // setSnackOpen(true)
        // setSnackContent({ severity: "info", message: "Adding new Shipper/Consignee ..." })

        const newShipperPayload = {
            company: '1',
            code: data.code?.toString().toUpperCase()||"",
            consignee: data?.code?.toString().toUpperCase()||"",
            yard: data.yard?.toString().toUpperCase()||"",
            name: data.name?.toString().toUpperCase()||"",
            address: data.address?.toString().toUpperCase()||"",
            city:data.city?.toString().toUpperCase()||"",
            state: data.state?.toString().toUpperCase()||"",
            zip: data.zip?.toString().toUpperCase()||"",
            // contactName:data.phone.toString().toUpperCase(),
            contactName: (primaryContactData && primaryContactData[0]?.contact_name)?.toString().toUpperCase() || "",
            contactPhone: (primaryContactData && primaryContactData[0]?.phone_number)?.toString().toUpperCase() || "",
            contactEmail: (primaryContactData && primaryContactData[0]?.email)?.toString().toUpperCase() || "",
            fax: data.fax?.toString().toUpperCase()||"",
            note1: data.note1?.toString().toUpperCase()||"",
            note2: data.note2?.toString().toUpperCase()||"",
            note3: data.note3?.toString().toUpperCase()||"",
            internalNotes:`${data.note1}${data.note2} ${data.note3}`?.toString().toUpperCase()||"",
            defaultContact:stop?.defaultContact
        }
        // Update parent here
        setStopState({ ...stopState, ...newShipperPayload });
        updateStops({ ...stopState, ...newShipperPayload });
        setNewShipper(newShipperPayload);
        setShipperSelect({ code: data.code.toString().toUpperCase(), name: data.name.toString().toUpperCase() });
        // if (addEditShipperMode) {
        //     PUT(`/shippers/${params?.locationNumber}`, newShipperPayload)
        //         .then(res => {
        //             if (res && res.httpCode === 200 && res.status === "success") {

        //                 setAddEditShipper(null);
        //                 setAddEditShipperMode(true);
        //                 setSnackOpen(true)
        //                 setSnackContent({ severity: "success", message: `${newShipperPayload.code} Consignee/Shipper added` })
        //             }
        //             else {
        //                 setSnackOpen(true)
        //                 setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper: ${res.message}` })
        //             }
        //         })
        //         .catch(error => {
        //             setSnackOpen(true)
        //             setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper` })
        //         })
        // } else {
        //     POST(`/shippers/${params?.locationNumber}`, newShipperPayload)
        //         .then(res => {
        //             console.log(res)
        //             if (res && res.httpCode === 200 && res.status === "success") {

        //                 setAddEditShipper(null);
        //                 setAddEditShipperMode(true);
        //                 setSnackOpen(true)
        //                 setSnackContent({ severity: "success", message: `${newShipperPayload.code} Consignee/Shipper added` })
        //             }
        //             else {
        //                 setSnackOpen(true)
        //                 setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper: ${res.message}` })
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error)
        //             setSnackOpen(true)
        //             setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper` })
        //         })
        // }
    }

    const resetShipper = () => {

        setAddEditShipper(null);
        setNewShipper({ ...stopState });

        if (addEditShipperMode) {
            setAddEditShipperMode(true);
            setShipperSelect({ code: stopState.consignee, name: stopState.name })
        } else if (!addEditShipperMode) {
            setAddEditShipperMode(false);
            setShipperSelect(null)
        }
    }
    // update stops layout if window size changes
    // useEffect(() => {
    //     let cb = function () {
    //         setOrientation(window.innerWidth < 1400 ? 'vertical' : 'horizontal');
    //     };
    //     window.addEventListener("resize", cb);

    //     return () => {
    //         window.removeEventListener("resize", cb);
    //     };
    // }, []);

    // useEffect(() => {
    //     console.log('Screen Status Changed')
    // }, [screenStatus])

    // handle collapsible
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // const handleUntender = (stop) => {
    //     if (params.controlNumber !== 'new') {
    //         const confirm = window.confirm('Are you sure?');
    //         if (confirm) {
    //             setSnackOpen(true)
    //             setSnackContent({ severity: "info", message: "Removing Tender changes..." })
    //             dispatch(unTenderType({ unTenderLoc: params.locationNumber, unTenderCntrlNum: params.controlNumber, unTenderStopNum: stop }))
    //                 .then(res => {
    //                     if (typeof res?.status !== 'undefined' && res.status === 'success') {
    //                         setSnackContent({ severity: 'success', message: res.message })
    //                     } else {
    //                         setSnackContent({ severity: "error", message: 'something went wrong, please try again with correct tender data!' })
    //                     }
    //                 })
    //         }
    //     }
    // }
    const handleCloseModal = () => {
      console.log("close")
      setAddEditShipper(null);
    };

    const handleUntender = async (location, control, stopNo) => {
        const confirm = window.confirm('Are you sure?');
        if (confirm) {
            setSnackOpen(true);
            setSnackContent({ severity: "info", message: "Removing Tender changes..." })
            dispatch(unTenderType({ unTenderLoc: location, unTenderCntrlNum: control, unTenderStopNum: stopNo }))
                .then(res => {
                    if (typeof res?.status !== 'undefined' && res.status === 'success') {
                        setSnackContent({ severity: 'success', message: res.message })

                        if(res.data.dropType === 'staywith'){
                            setDepartTruckSelect('');
                            setArriveTruckSelect('');
                            setArriveDriverSelect('');
                            setDepartDriverSelect('');
                            setStopState({ ...stopState, arriveTruck: '', departTruck: '', arriveTruckStatus: '', departTruckStatus: '', departDriver: '', arriveDriver: '', arriveDriverStatus: '', departDriverStatus: '' });
                            updateStops({ ...stopState, arriveTruck: '', departTruck: '', arriveTruckStatus: '', departTruckStatus: '', departDriver: '', arriveDriver: '', arriveDriverStatus: '', departDriverStatus: '' });
                        } else if(res.data.dropType === 'drop') {
                            setArriveTruckSelect('');
                            setArriveDriverSelect('');
                            setStopState({ ...stopState, arriveTruck: '', arriveTruckStatus: '', arriveDriver: '', arriveDriverStatus: '' });
                            updateStops({ ...stopState, arriveTruck: '', arriveTruckStatus: '', arriveDriver: '', arriveDriverStatus: ''});

                        } else if(res.data.dropType === 'pull') {
                            setDepartTruckSelect('');
                            setDepartDriverSelect('');
                            setStopState({ ...stopState, departTruck: '', departTruckStatus: '', departDriver: '', departDriverStatus: '' });
                            updateStops({ ...stopState, departTruck: '', departTruckStatus: '', departDriver: '', departDriverStatus: '' });
                        }

                    } else {
                        setSnackContent({ severity: "error", message: res?.message || 'Something went wrong, please try again!' })
                    }
                })
        }
    }
    const handleChangeShipperLookupField = () => {
        setIsTextField(!isTextField);
    };
    const handleShipperCodeChange = (value) => {
        console.log('Selected Shipper', value,stopState)

        setStopState({
            ...stopState,
            consignee: value,
            
        })
        // // Update parent here
        updateStops({
            ...stopState,
            consignee: value,
           
        })
    }
    return (
        <div className="StopsContainer">
            <Divider
                textAlign="left"
                className="dividersty"
            >
                <Chip label={`Stop ${stopState?.newStop?stopState.newStop:stopState.stop}`} size="small" variant="outlined" />
            </Divider>
            {/* <IconButton onClick={() => { handleDeleteStop({ ...stopState }) }}>
                <DeleteIcon />
            </IconButton> */}
            <div className="stopcontainerdiv">
                <GridContainer>
                    <GridItem gridProps={{ xs: 12, md: isTemplateScreen === true ? 6 : 4, lg: isTemplateScreen === true ? 6 : 4 }} >
                        <Paper variant="outlined" style={{ flex: 1, margin: 5, display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                            {
                                !addEditShipper &&
                                <table className="width_100" style={{ alignSelf: 'start' }}>
                                    <tbody className="width_100">

                                        {/* <tr>
                                    <td>
                                        <TextField
                                            label="Stop"
                                            size="small"
                                            disabled
                                            value={stopState.stop || ''}
                                        />
                                    </td>
                                    <td>
                                        <TextField
                                            label="Pickup Number"
                                            size="small"
                                            value={stopState.pickupNumber || ''}
                                            onChange={(event) => { setStopState({ ...stopState, pickupNumber: event.target.value }); updateStops({ ...stopState, pickupNumber: event.target.value }) }}
                                        />
                                    </td>
                                </tr> */}
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <div className="width_100" style={{ display: 'flex' }}>
                                                {isTextField ? (
                                                    <TextField
                                                        value={stopState?.consignee|| ''}
                                                        onChange={(e) => handleShipperCodeChange(e.target.value)}
                                                        label={(selectedOrderType === "INBOUND" || selectedOrderType === "IMPORT") ? "Code" : (selectedOrderType === "OUTBOUND" || selectedOrderType === "EXPORT") ? "Code" : ""}
                                                        style={{ flex: 1, textTransform: 'uppercase', background: checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].name`) ? "#EDE21196" : null }}
                                                        size="small"
                                                    />
                                                ) :(
                                                    <Autocomplete
                                                        disablePortal
                                                        //disableClearable
                                                        forcePopupIcon={false}
                                                        id="stop-shipper-select"
                                                        size="small"
                                                        className="width_100"
                                                        style={{ flex: 1, textTransform: 'uppercase' }}
                                                        value={shipperSelect}
                                                        options={shipperOptions}
                                                        getOptionLabel={(option) => `${option.code} - ${option.name} [${option.address || ''}, ${option.city || ''}, ${option.state || ''}]`}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                                    {`${option.code} - ${option.name || ""} [${option.address || ''}, ${option.city || ''}, ${option.state || ''}]`}
                                                                </li>
                                                            )
                                                        }}
                                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                                        renderInput={(params) => <TextField {...params} value={params.code} label={(selectedOrderType === "INBOUND" || selectedOrderType === "IMPORT") ? "Consignee" : (selectedOrderType === "OUTBOUND" || selectedOrderType === "EXPORT") ? "Shipper" : ""} 
                                                        style={{background:checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].name`)? "#EDE21196" : null }}/>}
                                                        onInputChange={handleShipperLookup}
                                                        onChange={handleShipperChange}
                                                        componentsProps={{
                                                            paper: {
                                                                sx: {
                                                                    width: 500
                                                                }
                                                            }
                                                        }}
                                                        disabled={disableFull}
                                                    />  )}
                                                    <IconButton onClick={handleAddEditShipperClick}>
                                                        {addEditShipperMode || shipperSelect?.code ? <EditIcon /> : <AddIcon />}
                                                    </IconButton>
                                                    {isEdiScreen &&(<IconButton onClick={handleChangeShipperLookupField}>
                                                        <CachedIcon /> 
                                                    </IconButton>)}
                                                </div>
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Name"
                                                    size="small"
                                                    className="width_100"
                                                    value={stopState.name || ''}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Address"
                                                    size="small"
                                                    className="width_100"
                                                    value={stopState.address || ''}
                                                    disabled
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="City"
                                                    size="small"
                                                    className="width_100"
                                                    value={stopState.city || ''}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <div>
                                                <TextField
                                                    label="State"
                                                    size="small"
                                                    className="width_50"
                                                    value={stopState.state || ''}
                                                    disabled
                                                    style={{paddingRight:'5%' }}
                                                />
                                                <TextField
                                                    label="Zip"
                                                    size="small"
                                                    className="width_50"
                                                    value={stopState.zip || ''}
                                                    disabled
                                                />
                                                </div>
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Phone 1"
                                                    size="small"
                                                    className="width_100"
                                                    value={stopState.defaultContact || ''}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Contact"
                                                    size="small"
                                                    className="width_100"
                                                    value={stopState.contactName || ''}
                                                    disabled
                                                />
                                                
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Phone 2"
                                                    size="small"
                                                    className="width_100"
                                                    value={stopState.contactPhone || ''}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td colSpan={2} className="width_50">
                                                <TextField
                                                    label="Email"
                                                    size="small"
                                                    fullWidth
                                                    className="width_100"
                                                    value={stopState.contactEmail || ''}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="PO/PU"
                                                    size="small"
                                                    className="width_100"
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 30 }}
                                                    value={stopState.pickupNumber || ''}
                                                    onChange={(event) => { setStopState({ ...stopState, pickupNumber: event.target.value }); updateStops({ ...stopState, pickupNumber: event.target.value }) }}
                                                    disabled={disableFull}
                                                //disabled
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Shipper B/L"
                                                    size="small"
                                                    className="width_100"
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 30 }}
                                                    value={stopState.shipperBillOfLadding || ''}
                                                    onChange={(event) => { setStopState({ ...stopState, shipperBillOfLadding: event.target.value }); updateStops({ ...stopState, shipperBillOfLadding: event.target.value }) }}
                                                    style={{background:checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].shipperBillOfLadding`)? "#EDE21196": null }}
                                                    disabled={disableFull}
                                                //disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Additional Ref 1 "
                                                    size="small"
                                                    className="width_100"
                                                    value={''}
                                                    disabled
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Additional Ref 2"
                                                    size="small"
                                                    className="width_100"
                                                    value={''}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <Divider></Divider>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <IconButton onClick={() => { handleAddStop({ ...stopState }, 'before') }}>
                                                    <AddIcon color="primary" />
                                                    <Typography color="primary" style={{ fontSize: '14px' }}>
                                                        STOP BEFORE
                                                    </Typography>
                                                </IconButton>
                                                <IconButton onClick={() => { handleAddStop({ ...stopState }, 'after') }}>
                                                    <AddIcon color="primary" />
                                                    <Typography color="primary" style={{ fontSize: '14px' }}>
                                                        STOP AFTER
                                                    </Typography>
                                                </IconButton>
                                                <IconButton onClick={() => { handleDeleteStop({ ...stopState }) }} disabled={stopsRef?.current.length===1} 
                                                style={{opacity: stopsRef?.current.length === 1 ? 0.5 : 1}}>
                                                    <DeleteIcon color={stopsRef?.current.length===1?"disabled":"primary"} />
                                                    <Typography color={stopsRef?.current.length===1?"textSecondary":"primary"} borderColor="primary" style={{ fontSize: '14px' }} >
                                                        REMOVE STOP
                                                    </Typography>
                                                </IconButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            {
                               <Dialog
                               open={addEditShipper}
                               onClose={handleCloseModal}
                               maxWidth="md"
                               fullWidth
                               PaperProps={{
                                 style: {
                                   minHeight: "400px",
                                 },
                               }}
                             >
                               {/* <DialogTitle>Shipper Consignee Form</DialogTitle> */}
                               <DialogContent>
                                 <ShipperConsigneeFormContainer
                                   onClose={handleCloseModal}
                                   addNewShipperCons={shipperSelect?.code?false:true}
                                   isEditScreen={shipperSelect?.code?true:false}
                                   isOrderScreen="true"
                                   shipperSelect={shipperSelect}
                                   shipperOptions={shipperOptions}
                                   stopState={stopState}
                                   newShipper={newShipper}
                                   handleAddShipper={handleAddShipper}
    

                                 />
                               </DialogContent>
                             </Dialog>
                                // addEditShipper &&
                                // <table className="width_100" style={{ alignSelf: 'start' }}>
                                //     <tbody className="width_100">
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Shipper/Cons Code"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.consignee || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 7 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, consignee: event.target.value })
                                //                     }}
                                //                     disabled={disableFull || addEditShipperMode}
                                //                 />
                                //             </td>
                                //             <td>
                                //             </td>
                                //         </tr>
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Shipper/Cons Name"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.name || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, name: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Address"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.address || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, address: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //         </tr>
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="City"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.city || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, city: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="State"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.state || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 2 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, state: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //         </tr>
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Zip"
                                //                     size="small"
                                //                     value={newShipper.zip || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, zip: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //             <td className="width_50">
                                //                 <PhoneNumberMask
                                //                     label="Phone"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.contactPhone || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, contactPhone: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //         </tr>
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Fax"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.fax || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, fax: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Yard"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.yard || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 1 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, yard: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                     select
                                //                     SelectProps={{
                                //                         native: true,
                                //                     }}
                                //                 >
                                //                     <option selected value={`N`}>N</option>
                                //                     <option value={`Y`}>Y</option >
                                //                 </TextField>
                                //             </td>
                                //         </tr>
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Notes 1"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.note1 || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, note1: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Notes 2"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.note2 || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, note2: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //         </tr>
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <TextField
                                //                     label="Notes 3"
                                //                     size="small"
                                //                     className="width_100"
                                //                     value={newShipper.note3 || ''}
                                //                     inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                //                     onChange={event => {
                                //                         setNewShipper({ ...newShipper, note3: event.target.value })
                                //                     }}
                                //                     disabled={disableFull}
                                //                 />
                                //             </td>
                                //             <td>
                                //             </td>
                                //         </tr>
                                //         <tr className="width_100">
                                //             <td className="width_50">
                                //                 <Button onClick={handleAddShipper}>
                                //                     {addEditShipperMode ? "Update" : "Add"}
                                //                 </Button>
                                //                 <Button onClick={() => { resetShipper() }}>
                                //                     Back
                                //                 </Button>
                                //             </td>
                                //             <td>
                                //             </td>
                                //         </tr>
                                //     </tbody>
                                // </table>
                            }
                        </Paper >
                    </GridItem>

                    <GridItem gridProps={{ xs: 12, md: isTemplateScreen === true ? 6 : 4, lg: isTemplateScreen === true ? 6 : 4 }} >
                        <Paper variant="outlined" style={{ flex: 1, margin: 5, display: 'flex', justifyContent: 'center', paddingTop: 10, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingBottom: '0.5rem' }}>
                                <div className="width_33"
                                style={{backgroundColor:checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].appointmentDate`)? "#EDE21196" : null }}>
                                    <CustomDatePicker
                                        label="Appointment Date"
                                        size="small"
                                        className="width_100"
                                        value={stopState.appointmentDate || ''}
                                        onValueChange={(selectedVal) => { setStopState({ ...stopState, appointmentDate: selectedVal }); updateStops({ ...stopState, appointmentDate: selectedVal }) }}
                                        disabled={disableFull}
                                    />
                                </div>
                                <div className="width_33">
                                    <TextField
                                        label="Time"
                                        size="small"
                                        // type="Time"
                                        fullWidth
                                        className="width_100"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 8 }}
                                        value={stopState.appointmentTime || ''}
                                        onChange={(event) => { setStopState({ ...stopState, appointmentTime: event.target.value }); updateStops({ ...stopState, appointmentTime: event.target.value }) }}
                                        style={{background:checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].appointmentTime`)? "#EDE21196" : null }}
                                        disabled={disableFull}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingLeft: '0.5rem' }}>
                                <div className="stoppaper2" style={{ display: 'flex', flexDirection: 'column', paddingRight: '0.5rem' }}>
                                    <div className="width_100 paddingbottom">
                                        <TextField
                                            label="Pieces"
                                            size="small"
                                            className="width_100"
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 12 }}
                                            value={stopState.totalPieces || ''}
                                            onChange={(event) => { setStopState({ ...stopState, totalPieces: event.target.value }); updateStops({ ...stopState, totalPieces: event.target.value }); setStopsUpdate(); }}
                                            disabled={disableFull}
                                        />
                                    </div>

                                    <div className="width_100 paddingbottom ">
                                        <TextField
                                            type="number"
                                            label="Weight"
                                            size="small"
                                            className="width_100"
                                            inputProps={{ maxLength: 10 }}
                                            value={stopState.totalWeight || ''}
                                            onChange={(event) => { setStopState({ ...stopState, totalWeight: event.target.value }); updateStops({ ...stopState, totalWeight: event.target.value }); setStopsUpdate(); }}
                                            style={{background:checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].totalWeight`)? "#EDE21196" : null }}
                                            disabled={disableFull}
                                        />
                                    </div>
                                    <div className="width_100 paddingbottom">
                                        <TextField
                                            label="Door Seal"
                                            size="small"
                                            className="width_100"
                                            value={stopState.doorSeal || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 30 }}
                                            onChange={(event) => { setStopState({ ...stopState, doorSeal: event.target.value }); updateStops({ ...stopState, doorSeal: event.target.value }); setStopsUpdate(); }}
                                            disabled={disableFull}
                                        />
                                    </div>
                                    <div className="width_100 paddingbottom">
                                        <TextField
                                            label="Commodity"
                                            size="small"
                                            multiline
                                            rows={3}
                                            fullWidth
                                            className="width_100"
                                            value={stopState.commodity || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 50 }}
                                            style={{background:checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].commodity`)? "#EDE21196": null }}
                                            onChange={(event) => { setStopState({ ...stopState, commodity: event.target.value }); updateStops({ ...stopState, commodity: event.target.value }) }}
                                            disabled={disableFull}
                                        />
                                    </div>
                                    <div className="width_100 paddingbottom">
                                        <TextField
                                            label="Temp Pulp"
                                            size="small"
                                            className="width_100"
                                            value={stopState.tempPulp || ''}
                                            disabled
                                            onChange={(event) => { setStopState({ ...stopState, doorSeal: event.target.value }); updateStops({ ...stopState, doorSeal: event.target.value }) }}
                                        />

                                    </div>
                                </div>

                                {!isTemplateScreen ? (<>  <div className="stoppaper2" style={{ display: 'flex', flexDirection: 'column', paddingRight: '0.5rem' }}>
                                    <div className="width_100 paddingbottom">
                                        <CustomDateTimePicker
                                            label="ETA Date & Time"
                                            size="small"
                                            className="width_100"
                                            value={`${stopState.etaDate}T${stopState.etaTime}` || ''}
                                            onValueChange={(timestamp) => {
                                                setStopState({ ...stopState, etaDate: timestamp.eDate, etaTime: timestamp.eTime });
                                                updateStops({ ...stopState, etaDate: timestamp.eDate, etaTime: timestamp.eTime })
                                            }}
                                            disabled={disableFull}
                                        />
                                    </div>
                                    <div className="width_100 paddingbottom">
                                        <CustomDateTimePicker
                                            label="Arrive Date & Time"
                                            size="small"
                                            className="width_100"
                                            value={`${stopState.arriveDate}T${stopState.arriveTime}` || ''}
                                            onValueChange={(timestamp) => {
                                                setStopState({ ...stopState, arriveDate: timestamp.eDate, arriveTime: timestamp.eTime });
                                                updateStops({ ...stopState, arriveDate: timestamp.eDate, arriveTime: timestamp.eTime })
                                            }}
                                            disabled={disableFull}
                                        />
                                    </div>

                                    <div className="width_100 paddingbottom">
                                        <CustomDateTimePicker
                                            label="Spot Date & Time"
                                            size="small"
                                            className="width_100"
                                            value={`${stopState.spotDate}T${stopState.spotTime}` || ''}
                                            onValueChange={(timestamp) => {
                                                setStopState({ ...stopState, spotDate: timestamp.eDate, spotTime: timestamp.eTime });
                                                updateStops({ ...stopState, spotDate: timestamp.eDate, spotTime: timestamp.eTime })
                                            }}
                                            disabled={disableFull}
                                        />
                                    </div>
                                    <div className="width_100 paddingbottom">
                                        <CustomDateTimePicker
                                            label="Stop U/L Date & Time"
                                            size="small"
                                            className="width_100"
                                            value={`${stopState.unloadDate}T${stopState.unloadTime}` || ''}
                                            onValueChange={(timestamp) => {
                                                setStopState({ ...stopState, unloadDate: timestamp.eDate, unloadTime: timestamp.eTime });
                                                updateStops({ ...stopState, unloadDate: timestamp.eDate, unloadTime: timestamp.eTime })
                                            }}
                                            disabled={disableFull}
                                        />
                                    </div>

                                    <div className="width_100 paddingbottom">
                                        <CustomDateTimePicker
                                            label="Avail Date & Time"
                                            size="small"
                                            className="width_100"
                                            value={`${stopState.availDate}T${stopState.availTime}` || ''}
                                            onValueChange={(timestamp) => {
                                                setStopState({ ...stopState, availDate: timestamp.eDate, availTime: timestamp.eTime });
                                                updateStops({ ...stopState, availDate: timestamp.eDate, availTime: timestamp.eTime })
                                            }}
                                            disabled={disableFull}
                                        />
                                    </div>

                                    <div className="width_100 paddingbottom">
                                        <CustomDateTimePicker
                                            label="Depart Date & Time"
                                            size="small"
                                            className="width_100"
                                            value={`${stopState.departDate}T${stopState.departTime}` || ''}
                                            onValueChange={(timestamp) => {
                                                setStopState({ ...stopState, departDate: timestamp.eDate, departTime: timestamp.eTime });
                                                updateStops({ ...stopState, departDate: timestamp.eDate, departTime: timestamp.eTime })
                                            }}
                                            disabled={disableFull}
                                        />
                                    </div>
                                </div>
                                    <div className="stoppaper2" style={{ display: 'flex', flexDirection: 'column', paddingRight: '0.5rem' }}>
                                        <div className="width_100 paddingbottom" style={{ visibility: 'hidden' }} >
                                            <TextField
                                                label=""
                                                size="small"
                                                disabled
                                                className="width_100"
                                                display="none"
                                            />
                                        </div>

                                        <div style={{display:'flex', flexDirection:'row', gap:'7px'}}>
                                            <div className="width_50 paddingbottom">
                                                <Autocomplete
                                                    disablePortal
                                                    //disableClearable
                                                    forcePopupIcon={false}
                                                    size="small"
                                                    className="width_50"
                                                    id="stop-arrive-truck-select"
                                                    value={arriveTruckSelect}
                                                    options={arriveTruckOptions}
                                                    getOptionLabel={(option) => (option.truck && option.name && option.driverId) ? `${option.truck}` : option?.truck || ""}
                                                    style={screenStatus || orientation === 'vertical' ? { minWidth: "100%" } : { minWidth: "100%" }}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={props['data-option-index']}>
                                                                {/* {`${option.truck} ${option.name} [${option.driverId}]`} */}
                                                                {`${option.truck}`}
                                                            </li>
                                                        )
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.truck === value.truck}
                                                    renderInput={(params) => <TextField {...params} value={params.truck} label="Truck" />}
                                                    onInputChange={handleArriveTruckLookup}
                                                    onChange={(event, value) => {
                                                        if (value !== null && value !== '' && typeof value !== 'undefined') {
                                                            // Retain the check only when the user selects a truck
                                                            if (!canEnterTruckDetailsArrive(stopState.stop, stopsRef.current)) {
                                                                alert("Cannot enter arrival truck details at this stop. Please complete previous stop departure truck details.");
                                                                return;
                                                            }
                                                            setArriveTruckSelect(value);
                                                            setArriveDriverSelect({id: value.driverId});
                                                            setStopState({ ...stopState, arriveTruck: value.truck, arriveDriver: value.driverId });
                                                            updateStops({ ...stopState, arriveTruck: value.truck, arriveDriver: value.driverId })
                                                        } else {
                                                            
                                                            if(params.controlNumber !== 'NEW' && (stopState.departTruck !== 0 || stopState.arriveTruck !== 0)){
                                                                handleUntender(
                                                                    params.locationNumber,
                                                                    params.controlNumber,
                                                                    stopState.stop
                                                                )
                                                            }else{
                                                                setArriveTruckSelect('');
                                                                setArriveDriverSelect('');
                                                                setStopState({ ...stopState, arriveTruck: '', arriveTruckStatus: '', arriveDriver: '', arriveDriverStatus:'' });
                                                                updateStops({ ...stopState, arriveTruck: '', arriveTruckStatus: '', arriveDriver: '', arriveDriverStatus:'' })
                                                            }
                                                        }


                                                    }}
                                                    // disabled={disableFull || !canEnterTruckDetailsArrive(stopState.stop)}
                                                    disabled={disableFull}
                                                />
                                            </div>
                                            <div className="width_50 paddingbottom" >
                                                <Autocomplete
                                                    disablePortal
                                                    //disableClearable
                                                    forcePopupIcon={false}
                                                    size="small"
                                                    className="width_50"
                                                    id="stop-arrive-driver-select"
                                                    value={arriveDriverSelect}
                                                    options={allDrivers}
                                                    getOptionLabel={(option) => (option.id) ? `${option.id}` : option?.id || ""}
                                                    style={screenStatus || orientation === 'vertical' ? { minWidth: "100%" } : { minWidth: "100%" }}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={props['data-option-index']}>
                                                                {`${option.id} - ${option.driverName}`}
                                                            </li>
                                                        )
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    renderInput={(params) => (
                                                        <TextField 
                                                            {...params} 
                                                            value={params.id} 
                                                            label="Driver" 
                                                            />
                                                        )}
                                                    componentsProps={{ popper: { style: { width: 300 } } }}
                                                    onInputChange={handleArriveTruckLookup}
                                                    onChange={(event, value) => {
                                                        if (value !== null && value !== '' && typeof value !== 'undefined' ) {
                                                            // Retain the check only when the user selects a truck
                                                            if (!canEnterTruckDetailsArrive(stopState.stop, stopsRef.current)) {
                                                                alert("Cannot enter arrival truck details at this stop. Please complete previous stop departure truck details.");
                                                                return;
                                                            }
                                                            setArriveDriverSelect(value);
                                                            setStopState({ ...stopState, arriveDriver: value.id });
                                                            updateStops({ ...stopState, arriveDriver: value.id })
                                                        } else {
                                                            if(params.controlNumber !== 'NEW' && (stopState.departTruck !== 0 || stopState.arriveTruck !== 0)){
                                                                handleUntender(
                                                                    params.locationNumber,
                                                                    params.controlNumber,
                                                                    stopState.stop
                                                                )
                                                            } else {
                                                                setArriveTruckSelect('');
                                                                setArriveDriverSelect('');
                                                                setStopState({ ...stopState, arriveTruck: '', arriveTruckStatus: '', arriveDriver: '', arriveDriverStatus:'' });
                                                                updateStops({ ...stopState, arriveTruck: '', arriveTruckStatus: '', arriveDriver: '', arriveDriverStatus:'' })
                                                            }
                                                            // setArriveDriverSelect('')
                                                            // setStopState({ ...stopState, arriveDriver: '' });
                                                            // updateStops({ ...stopState, arriveDriver: '' })
                                                        }
    
    
                                                    }}
                                                    // disabled={disableFull || !canEnterTruckDetailsArrive(stopState.stop)}
                                                    disabled={disableFull}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display:'flex', flexDirection:'row', gap:'7px'}}>
                                            <div className="width_50 paddingbottom">
                                           
                                                <TextField
                                                label="Status"
                                                size="small"
                                                className="width_50"
                                                //value={(stopState.arriveTruckStatus == 'T' && stopState.arriveTenderStatus) ? stopState.arriveTenderStatus : stopState.arriveTruckStatus || '' }
                                                value={(['A','C','T'].includes(stopState.arriveTruckStatus)) ? stopState.arriveTruckStatus : '' || '' }
                                                onChange={(event) => {
                                                    if (event.target.value !== null && event.target.value !== '') {
                                                        // Retain the check only when the user selects a truck
                                                        if (!canEnterTruckDetailsArrive(stopState.stop, stopsRef.current)) {
                                                            alert("Cannot enter arrival truck details at this stop. Please complete previous stop departure truck details.");
                                                            return;
                                                        }
                                                        setStopState({ ...stopState, arriveTruckStatus: event.target.value, arriveDriverStatus:(event.target.value==='C')?'C':stopState.arriveDriverStatus });
                                                        updateStops({ ...stopState, arriveTruckStatus: event.target.value, arriveDriverStatus:(event.target.value==='C')?'C':stopState.arriveDriverStatus })
                                                    } else {
                                                        setStopState({ ...stopState, arriveTruckStatus: '' });
                                                        updateStops({ ...stopState, arriveTruckStatus: '' })
                                                    }

                                                }}
                                                disabled={disableFull}
                                                select
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                // sx={{background: (stopState.arriveTruckStatus && stopState.arriveTruckStatus != 'C') ? 'yellow' :null}}
                                                style={screenStatus || orientation === 'vertical' ? { minWidth: "100%" } : { minWidth: "100%" }}
                                            >
                                                <option value={``}></option>
                                                {/* <option value={`O`} disabled>O</option> */}
                                                <option value={`A`} disabled>A</option>
                                                <option value={`C`}>C</option>
                                                <option value={`T`} disabled>T</option>
                                                {/* <option value={`R`}>R</option> */}
                                            </TextField>
                                            </div>
                                            <div className="width_50 paddingbottom">
                                           
                                           <TextField
                                           label="Status"
                                           size="small"
                                           className="width_50"
                                           value={(stopState.arriveDriverStatus) ? stopState.arriveDriverStatus : '' || '' }
                                           onChange={(event) => {
                                            if (event.target.value !== null && event.target.value !== '') {
                                                // Retain the check only when the user selects a truck
                                                if (!canEnterTruckDetailsArrive(stopState.stop, stopsRef.current)) {
                                                    alert("Cannot enter arrival truck details at this stop. Please complete previous stop departure truck details.");
                                                    return;
                                                }
                                                   setStopState({ ...stopState, arriveDriverStatus: event.target.value });
                                                   updateStops({ ...stopState, arriveDriverStatus: event.target.value })
                                               } else {
                                                   setStopState({ ...stopState, arriveDriverStatus: ''});
                                                   updateStops({ ...stopState, arriveDriverStatus: ''})
                                               }

                                           }}
                                           disabled={disableFull}
                                           select
                                           SelectProps={{
                                               native: true,
                                           }}
                                           // sx={{background: (stopState.arriveDriverStatus && stopState.arriveDriverStatus != 'C') ? 'yellow' :null}}
                                           style={screenStatus || orientation === 'vertical' ? { minWidth: "100%" } : { minWidth: "100%" }}
                                       >
                                            <option value={``}></option>
                                            <option value={`O`} disabled>O</option>
                                            <option value={`A`} disabled>A</option>
                                            <option value={`R`} disabled>R</option>
                                            <option value={`C`} disabled>C</option>
                                       </TextField>
                                       </div>
                                        </div>
                                        <div className="width_100 paddingbottom" style={{ visibility: 'hidden' }}>
                                            <TextField
                                                label=""
                                                size="small"
                                                disabled
                                                className="width_100"
                                                display="none"
                                            />
                                        </div>
                                        
                                        <div style={{display:'flex', flexDirection:'row', gap:'7px'}}>
                                            <div className="width_50 paddingbottom">
                                                <Autocomplete
                                                    disablePortal
                                                    //disableClearable
                                                    forcePopupIcon={false}
                                                    id="stop-depart-truck-select"
                                                    size="small"
                                                    className="width_100"
                                                    InputProps={{ shrink: true }}
                                                    InputLabelProps={{ shrink: true }}
                                                    value={departTruckSelect || ""}
                                                    options={departTruckOptions}
                                                    getOptionLabel={(option) => (option.truck && option.name && option.driverId) ? `${option.truck}` : option?.truck || ""}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={props['data-option-index']}>
                                                                {/* {`${option.truck} - ${option.name} [${option.driverId}]`} */}
                                                                {`${option.truck}`}
                                                            </li>
                                                        )
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.truck === value.truck}
                                                    renderInput={(params) => <TextField {...params} value={params.truck} label="Truck" />}
                                                    onInputChange={handleDepartTruckLookup}
                                                    onChange={(event, value) => {
                                                        if (value !== null && value !== '') {
                                                            // Retain the check only when the user selects a truck
                                                            if (!canEnterTruckDetailsDepart(stopState)) {
                                                                alert("Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop..");
                                                                    return;
                                                            }
                                                            setDepartTruckSelect(value);
                                                            setDepartDriverSelect({id: value.driverId})
                                                            setStopState({ ...stopState, departTruck: value.truck, departDriver: value.driverId });
                                                            updateStops({ ...stopState, departTruck: value.truck, departDriver: value.driverId })
                                                        } else {
                                                            if(params.controlNumber !== 'NEW' && (stopState.departTruck !== 0 || stopState.arriveTruck !== 0)){
                                                                handleUntender(
                                                                    params.locationNumber,
                                                                    params.controlNumber,
                                                                    stopState.stop
                                                                )
                                                            } else {
                                                                setDepartTruckSelect('');
                                                                setDepartDriverSelect('');
                                                                setStopState({ ...stopState, departTruck: '', departTruckStatus: '' });
                                                                updateStops({ ...stopState, departTruck: '', departTruckStatus: '' })
                                                            }
                                                        }
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </div>
                                            <div className="width_50 paddingbottom">
                                            <Autocomplete
                                                disablePortal
 
                                                //disableClearable
                                                forcePopupIcon={false}
                                                size="small"
                                                className="width_50"
                                                id="stop-arrive-driver-select"
                                                value={departDriverSelect}
                                                options={allDrivers}
                                                getOptionLabel={(option) => (option.id) ? `${option.id}` : option?.id || ""}
                                                style={screenStatus || orientation === 'vertical' ? { minWidth: "100%" } : { minWidth: "100%" }}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={props['data-option-index']}>
                                                            {`${option.id} - ${option.driverName}`}
                                                        </li>
                                                    )
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} value={params.id} label="Driver" />}
                                                componentsProps={{ popper: { style: { width: 300 } } }}
                                                onInputChange={handleArriveTruckLookup}
 
                                                onChange={(event, value) => {
                                                    if (value !== null && value !== ''&& typeof value !== 'undefined') {
                                                        if (!canEnterTruckDetailsDepart(stopState)) {
                                                            alert("Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop..");
                                                                return;
                                                        }
                                                        setDepartDriverSelect(value);
                                                        setStopState({ ...stopState, departDriver: value.id });
                                                        updateStops({ ...stopState, departDriver: value.id })
                                                    } else {
                                                        if(params.controlNumber !== 'NEW' && (stopState.departTruck !== 0 || stopState.arriveTruck !== 0)){
                                                            handleUntender(
                                                                params.locationNumber,
                                                                params.controlNumber,
                                                                stopState.stop
                                                            )
                                                        } else {
                                                            setDepartTruckSelect('');
                                                            setDepartDriverSelect('');
                                                            setStopState({ ...stopState, departTruck: '', departTruckStatus: '' });
                                                            updateStops({ ...stopState, departTruck: '', departTruckStatus: '' })
                                                        }
                                                    }
 
 
                                                }}
                                                // disabled={disableFull || !canEnterTruckDetailsArrive(stopState.stop)}
                                                disabled={disableFull}
                                            />
                                        </div>
                                        </div>
                                        <div style={{display:'flex', flexDirection:'row', gap:'7px'}}>
                                            <div className="width_50 paddingbottom">
                                                <TextField
                                                    label="Status"
                                                    size="small"
                                                    className="width_100"
                                                    value={(['A','C','T'].includes(stopState.departTruckStatus)) ? stopState.departTruckStatus : '' || ''}
                                                    onChange={(event) => {
                                                        if (event.target.value!== null && event.target.value !== '') {
                                                            // Retain the check only when the user selects a truck
                                                            if (!canEnterTruckDetailsDepart(stopState)) {
                                                                alert("Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop..");
                                                                    return;
                                                            }
                                                            setStopState({ ...stopState, departTruckStatus: event.target.value, departDriverStatus:(event.target.value==='C')?'C':stopState.departDriverStatus });
                                                            updateStops({ ...stopState, departTruckStatus: event.target.value, departDriverStatus:(event.target.value==='C')?'C':stopState.departDriverStatus })
                                                        } else {
                                                            setStopState({ ...stopState, departTruckStatus: '' });
                                                            updateStops({ ...stopState, departTruckStatus: '' })
                                                        }

                                                    }}
                                                    disabled={disableFull}
                                                    select
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                   
                                                    
                                                    style={screenStatus || orientation === 'vertical' ? { minWidth: "100%" } : { minWidth: "100%" }}
                                                >
                                                    <option value={``}></option>
                                                    <option value={`A`} disabled>A</option>
                                                    <option value={`C`}>C</option>
                                                    <option value={`T`} disabled>T</option>
                                                </TextField>
                                            </div>
                                            <div className="width_50 paddingbottom">
                                            <TextField
                                                label="Status"
                                                size="small"
                                                className="width_100"
                                                value={(stopState.departDriverStatus !== 0) ? stopState.departDriverStatus : ''  || ''}
                                                onChange={(event) => {
                                                    if (event.target.value!== null && event.target.value !== '') {
                                                        // Retain the check only when the user selects a truck
                                                        if (!canEnterTruckDetailsDepart(stopState)) {
                                                            alert("Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop..");
                                                                return;
                                                        }
                                                        setStopState({ ...stopState, departDriverStatus: event.target.value });
                                                        updateStops({ ...stopState, departDriverStatus: event.target.value })
                                                    } else {
                                                        setStopState({ ...stopState, departDriverStatus: ''});
                                                        updateStops({ ...stopState, departDriverStatus: ''})
                                                    }

                                                }}
                                                disabled={disableFull}
                                                select
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                
                                                style={screenStatus || orientation === 'vertical' ? { minWidth: "100%" } : { minWidth: "100%" }}
                                            >
                                                <option value={``}></option>
                                                <option value={`O`} disabled>O</option>
                                                <option value={`A`} disabled>A</option>
                                                <option value={`R`} disabled>R</option>
                                                <option value={`C`} disabled>C</option>
                                            </TextField>
                                        </div>
                                        </div>
                                    </div>
                                </>) : null}
                            </div>
                            {!isTemplateScreen ? (<>
                                <div>
                                    <Divider></Divider>
                                </div>
                                <div>
                                    <IconButton onClick={() => {

                                        // if (!canEnterTruckDetailsArrive(stopState.stop)) {
                                        //     // Display error message here
                                        //     alert("Cannot enter truck details at this stop. Please complete previous stop's truck details.");
                                        //     return;
                                        // }

                                        tenderSelectStops(stopState.stop)
                                    }} >
                                        <Typography color="primary" style={{ fontSize: '14px' }} >
                                            TENDER / ASSIGN
                                        </Typography>
                                    </IconButton>
                                    {/* <IconButton 
                                        disabled={(stopState.departTruck === 0 && stopState.arriveTruck === 0) ? true : false}
                                        onClick={async() => {
                                            if(stopState.departTruck !== 0 || stopState.arriveTruck !== 0){
                                                await handleUntender(
                                                    params.locationNumber,
                                                    params.controlNumber,
                                                    stopState.stop
                                                )
                                            }
                                    }}>
                                        <Typography color="primary" style={{ fontSize: '14px' }} >
                                            REMOVE
                                        </Typography>
                                    </IconButton> */}
                                    <IconButton 
                                        disabled={(stopState.departTruck || stopState.arriveTruck ) ? false : true}
                                        onClick={async() => {
                                            if(stopState.departTruck !== 0 || stopState.arriveTruck !== 0){
                                                await handleUntender(
                                                    params.locationNumber,
                                                    params.controlNumber,
                                                    stopState.stop
                                                )
                                            }
                                    }}
                                    style={{opacity:(stopState.departTruck  || stopState.arriveTruck ) ? 1 : 0.5}}
                                    >
                                        <Typography color={(stopState.departTruck || stopState.arriveTruck ) ?"primary":"textSecondary"} borderColor="primary" style={{ fontSize: '14px' }} >
                                        {/* <Typography color={(stopState.departTruck === 0 && stopState.arriveTruck === 0) ?"": "primary"} style={{ fontSize: '14px' }} > */}
                                            REMOVE
                                        </Typography>
                                    </IconButton>
                                    <IconButton
                                        disabled={(params.controlNumber === 'new') ? true : false}
                                        onClick={() =>
                                            dispatch(
                                                getPOD(
                                                    params.locationNumber,
                                                    params.controlNumber,
                                                    stopState.stop
                                                )
                                            )
                                        } >
                                        <Typography color="primary" style={{ fontSize: '14px' }}>
                                            SEND/PRINT POD
                                        </Typography>
                                    </IconButton>
                                </div></>) : null}

                        </Paper>
                    </GridItem>

                    <GridItem gridProps={{ xs: 12, md: isTemplateScreen === true ? 6 : 4, lg: isTemplateScreen === true ? 6 : 4 }}>


                        <Paper variant="outlined" className="stoppaper3">
                            <table className="width_100" style={{ alignSelf: 'start' }}>
                                <tbody >
                                    {!isTemplateScreen && <><tr style={{ paddingTop: 20 }}>
                                        <td >
                                            <TextField
                                                label="Internal Notes"
                                                multiline
                                                rows={4}
                                                size="small"
                                                fullWidth
                                                disabled
                                                value={stopState.internalNotes || ''}
                                                onChange={(event) => {
                                                    setStopState({ ...stopState, internalNotes: event.target.value });
                                                    updateStops({ ...stopState, internalNotes: event.target.value })
                                                }}
                                            // style={screenStatus || orientation === 'vertical' ? { minWidth: 220 } : { minWidth: 220 }}
                                            />
                                        </td>
                                    </tr></>}
                                    <tr>
                                        <td style={{ paddingTop: 20 }}>
                                            <TextField
                                                label="Driver Notes 1"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                size="small"
                                                value={stopState.driverNotes || ""}
                                                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                onChange={(event) => {
                                                    setStopState({ ...stopState, driverNotes: event.target.value });
                                                    updateStops({ ...stopState, driverNotes: event.target.value })
                                                }}
                                                style={{background:checkNonMatchingFields(nonMatchingFields, `stops[${stopState.stop-1}].driverNotes`)? "#EDE21196" : null }}
                                                disabled={disableFull}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ paddingTop: 20 }}>
                                            <TextField
                                                label="Driver Notes 2"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                size="small"
                                                value={stopState.driverNotes2 || ""}
                                                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                onChange={(event) => {
                                                    setStopState({ ...stopState, driverNotes2: event.target.value });
                                                    updateStops({ ...stopState, driverNotes2: event.target.value })
                                                }}
                                                disabled={disableFull}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Paper>
                    </GridItem>

                    <GridItem gridProps={{ xs: 12, md: isTemplateScreen === true ? 6 : 4, lg: isTemplateScreen === true ? 6 : 4 }}>

                        {isTemplateScreen?(                           
                        <Paper variant="outlined" className="stoppaper3">
                            <table className="width_100" style={{ alignSelf: 'start' }}>
                                <tbody >
                                    <tr style={{ paddingTop: 20 }}>
                                        <td >
                                            <TextField
                                                label="Internal Notes"
                                                multiline
                                                rows={9}
                                                size="small"
                                                fullWidth
                                                disabled
                                                value={stopState.internalNotes || ''}
                                                onChange={(event) => {
                                                    setStopState({ ...stopState, internalNotes: event.target.value });
                                                    updateStops({ ...stopState, internalNotes: event.target.value })
                                                }}
                                            // style={screenStatus || orientation === 'vertical' ? { minWidth: 220 } : { minWidth: 220 }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Paper>
                        ):null}
                    </GridItem>
                </GridContainer>
            </div>
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
                <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
            {
                tenderModal && (
                    <TenderModalNew
                        modalState={tenderModal}
                        handleModalState={(state) => setTenderModal(state)}
                        row={{
                            lineNumber: selectedStops,
                            control: params.controlNumber,
                            locationNumber: params.locationNumber,
                            io: (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') ? 'I' : (selectedOrderType === 'OUTBOUND' || selectedOrderType === 'EXPORT') ? 'O' : ''
                        }}
                        type={`TENDER / ASSIGN`}
                        handleTenderSubmitState={(tenderPayload) => {
                            let truckData = arriveTruckOptions.filter(e => e.driverId === tenderPayload.driverId);
                            let driverData = (truckData?.[0])?{id:truckData?.[0]?.driverId,name:truckData?.[0]?.name}:{}
                            // if(tenderPayload.tenderStatus === 'A'){
                            //     tenderPayload.tenderStatus = '';
                            // }
                            if (tenderPayload.drop === 'drop') {
                                setArriveTruckSelect(truckData?.[0])
                                setArriveDriverSelect(driverData)
                                setStopState({ ...stopState, arriveTruck: truckData?.[0]?.truck, arriveDriver: tenderPayload.driverId, arriveTruckStatus: tenderPayload.tenderStatus, arriveDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'' });
                                updateStops({ ...stopState, arriveTruck: truckData?.[0]?.truck, arriveDriver: tenderPayload.driverId, arriveTruckStatus: tenderPayload.tenderStatus, arriveDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'' })
                            } else if (tenderPayload.drop === 'pull') {
                                setDepartTruckSelect(truckData?.[0])
                                setDepartDriverSelect(driverData)
                                setStopState({ ...stopState, departTruck: truckData?.[0]?.truck, departDriver: tenderPayload.driverId, departTruckStatus: tenderPayload.tenderStatus, departDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'' });
                                updateStops({ ...stopState, departTruck: truckData?.[0]?.truck, departDriver: tenderPayload.driverId, departTruckStatus: tenderPayload.tenderStatus, departDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'' })
                            } else if (tenderPayload.drop === 'staywith') {
                                setArriveTruckSelect(truckData?.[0])
                                setDepartTruckSelect(truckData?.[0])
                                setArriveDriverSelect(driverData)
                                setDepartDriverSelect(driverData)
                                setStopState({ ...stopState, arriveTruck: truckData?.[0]?.truck,departTruck: truckData[0]?.truck, arriveTruckStatus: tenderPayload.tenderStatus,departTruckStatus: tenderPayload.tenderStatus, arriveDriver: tenderPayload.driverId, arriveDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'', departDriver: tenderPayload.driverId, departDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'' });
                                updateStops({ ...stopState, arriveTruck: truckData?.[0]?.truck,departTruck: truckData[0]?.truck, arriveTruckStatus: tenderPayload.tenderStatus,departTruckStatus: tenderPayload.tenderStatus, arriveDriver: tenderPayload.driverId, arriveDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'', departDriver: tenderPayload.driverId, departDriverStatus:(tenderPayload.tenderStatus==='T')?'O':'' })
                            }
                        }}
                        canEnterTruckDetailsDepart={canEnterTruckDetailsDepart}
                        canEnterTruckDetailsArrive={canEnterTruckDetailsArrive}
                        stopState={stopState}
                        stopsRef={stopsRef}
                    />
                )
            }
        </div >
    );
}