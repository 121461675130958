/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import {
    Stack,
    Box,
    Button,
    Typography,
    FormLabel,
    MenuItem,
    FormControl,
    TextField,
    FormControlLabel,
    Checkbox,
    Tooltip
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TraceWrapper } from "../../components/styledComponents";
import {
    DataGridPro,
    GridToolbar,
    GridActionsCellItem,
    GridRowModes,
    GridCellModes,
    useGridApiRef,
    GridToolbarQuickFilter,
    DEFAULT_GRID_AUTOSIZE_OPTIONS,
    GridToolbarContainer,
    GridToolbarFilterButton,
    gridFilteredSortedRowEntriesSelector
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { getTraceData, bulkEditReq } from "../../store/actions/traceActions";

// import custom date picker
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomTimePicker from '../../components/CustomTimePicker';
import PickupPointAutoComplete from './components/PickupPointAutoComplete';
import ShipperAutoComplete from './components/ShipperAutoComplete';
import moment from 'moment';
import {
    sanitizeStringForDBField,
    convertYMDToMDY,
    convertMDYToYMD
} from '../../utils/global-functions';

function CustomToolbar({ setFilterButtonEl }) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton ref={setFilterButtonEl} />
        </GridToolbarContainer>
    );
}

export default function Inbound({ navigation }) {
    const dispatch = useDispatch();
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [filterField, setFilterField] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [shipperArrObj, setShipperArrObj] = useState([]);
    const [isDomesticChecked, setIsDomesticChecked] = useState(false);

    const [filterButtonEl, setFilterButtonEl] = useState(null);

    // Bulk edit save
    const apiRef = useGridApiRef();
    const gridRef = useRef();

    const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
    const unsavedChangesRef = useRef({
        unsavedRows: {},
        rowsBeforeChange: {},
    });
    const [isSaving, setIsSaving] = useState(false);
    // const [sorting, setSorting] = useState({ field: '', direction: 'asc' });
    const [sortModel, setSortModel] = useState([]);

    useEffect(() => {
        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            fetchDataFromServer()
        }
    }, [ordersLocation])

    useEffect(() => {
        adjustColumnSize();
    }, [rows, rowSelectionModel, isSaving, isDomesticChecked, snackOpen])

    // handle show all button click
    const handleShowAll = () => {
        setFilterField('');
        setFilterValue('');
        fetchDataFromServer()
    }

    /**
     * Handle remove invalid button click
     * Valid container numbers are 4 alpha and between 5 and 7 numeric:
     * [A-Z]{4}[0-9]{5,7}
     */
    const handleInvalidRemove = () => {
        const regex = /^[A-Za-z]{4}[0-9]{5,7}$/;
        let filterRows = rows.filter((e) => regex.test(e.unit));
        setRows(filterRows);
    }

    /**
     * Handle remove selected button click
     * manually select row & remove rows from the view
     */
    const handleRemoveSelected = () => {
        let filterRows = rows.filter((e) => !rowSelectionModel.includes(e.id));
        setRowSelectionModel([]);
        setRows(filterRows);
    }

    /**
     * Handle copy unit button click
     * Copy only unit numbers
     */
    const handleCopyUnitToClipboard = () => {
        const filteredSortedRowsAndIds = gridFilteredSortedRowEntriesSelector(apiRef);

        if (navigator.clipboard) {
            //const unitNumbers = rows.filter((e, indx) => rowSelectionModel.includes(e.id)); //.map((item, indx) => isDomesticChecked ? removeLeadingZerosAfter4(item.unit) : item.unit).join('\n');
            const unitNumbers = filteredSortedRowsAndIds.filter((e, indx) => rowSelectionModel.includes(e.id)).map((item, indx) => isDomesticChecked ? removeLeadingZerosAfter4(item.model.unit) : item.model.unit).join('\n');
            // let unitNumbersArr = [];
            // rowSelectionModel.forEach((item, index) => {
            //     const row = filteredSortedRowsAndIds.filter(obj => obj.id === item);
            //     if (row.length > 0 && row[0] !== undefined) {
            //         const extractUnit = isDomesticChecked ? removeLeadingZerosAfter4(row[0].model.unit) : row[0].model.unit
            //         unitNumbersArr.push(extractUnit);
            //     }
            // });

            // const unitNumbers = unitNumbersArr.join('\n');

            setSnackOpen(true)
            navigator.clipboard.writeText(unitNumbers)
                .then(() => {
                    setSnackContent({ severity: "info", message: 'Copied to clipboard!' })
                    setTimeout(() => {
                        setSnackOpen(false)
                    }, 2000)
                })
                .catch((err) => {
                    setSnackContent({ severity: "error", message: `${'Failed to copy:' + err}` })
                });
        }
    }

    function removeLeadingZerosAfter4(str) {
        if (str.length > 10) {
            str = str.slice(0, -1)
        }
        const firstFour = str.slice(0, 4);
        const rest = str.slice(4).replace(/^0+/, '');
        const newStr = firstFour + rest;
        return newStr;
    }
    /**
     * Handle save change button click
     */
    const saveChanges = async () => {
        try {
            let postData = Object.values(unsavedChangesRef.current.unsavedRows).map(el => {
                return {
                    controlNumber: el.control,
                    orderType: el.orderType,
                    totalStops: el.totalStops,
                    equipmentDetails: {
                        unitNumber: sanitizeStringForDBField(el.unit, 13),
                        chassisNumber: sanitizeStringForDBField(el.chassis, 15, true),
                        realeaseNumber: sanitizeStringForDBField(el.release, 10),
                        vessel: sanitizeStringForDBField(el.vessel, 25),
                        voyageNumber: sanitizeStringForDBField(el.voyage, 5),
                        billingBooking: sanitizeStringForDBField(el.booking, 25),
                    },
                    pickup: {
                        poruPoint: sanitizeStringForDBField(el.puloc, 5),
                        disposition:sanitizeStringForDBField(el.dispLoc, 5),
                        notifyDate: convertMDYToYMD(el.notifyDate),
                        notifyTime: el.notifyTime,
                        cuttOffDate: convertMDYToYMD(el.lfd),
                        cutOffTime: el.lfdTime,
                        etaDate: convertMDYToYMD(el.etaDate),
                        etaTime: el.etaTime,
                    },
                    shipper: shipperArrObj[el.id] ? shipperArrObj[el.id] : el.shipperObjServer,
                    stops: {
                        lineNumber: 1,
                        apptDate: convertMDYToYMD(el.apptDate),
                        apptTime: el.apptTime,
                        etaDate: convertMDYToYMD(el.etaDate),
                        etaTime: el.etaTime
                    }
                }
            });

            // Persist updates in the database
            setIsSaving(true);

            let apiRes = await bulkEditReq(postData, ordersLocation.locationNumber);
            if (apiRes === true) {
                setSnackOpen(true);
                setSnackContent({ severity: "success", message: 'Records has been updated successfully!' })

                // Update local rows after successfull Update
                setRows(rows.map(row => {
                    if (unsavedChangesRef.current.unsavedRows[row.id]?.id == row.id) {
                        row = { ...unsavedChangesRef.current.unsavedRows[row.id], shipperObjServer: { ...unsavedChangesRef.current.unsavedRows[row.id].shipperObjServer } }
                        return row;
                    }
                    return row
                }))


                setHasUnsavedRows(false);
                unsavedChangesRef.current = {
                    unsavedRows: {},
                    rowsBeforeChange: {},
                };
                setIsSaving(false);
                // setTimeout(() => {
                //     fetchDataFromServer();
                //     setIsSaving(false);
                // }, 5000)

            } else if (apiRes === false) {
                setSnackOpen(true);
                setSnackContent({ severity: "error", message: 'Failed to save records, please try after sometime!' })
                setIsSaving(false);
            }

        } catch (error) {
            setIsSaving(false);
        }
    };

    /**
     * Handle discard changes button click
     */
    const discardChanges = () => {
        setHasUnsavedRows(false);
        apiRef.current.updateRows(
            Object.values(unsavedChangesRef.current.rowsBeforeChange),
        );
        unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
        };
    };

    /**
     * Handle Apply filter button click
     */
    const applyFilter = () => {
        fetchDataFromServer([{
            clause: "AND",
            field: filterField,
            operand: "IS",
            value: `${filterValue}*`
        }])
    }

    /**
     * Get data from server
     */
    const fetchDataFromServer = (searchCriteria = []) => {
        let loadsSearchCriteria = [
            ...searchCriteria,
            {
                clause: "AND",
                field: "locationNumber",
                operand: "IS",
                value: ordersLocation.locationNumber
            },
            // {
            //     clause: "AND",
            //     field: "stops.lineNumber",
            //     operand: "IS",
            //     value: 1
            // },
            {
                clause: "AND",
                field: "stops.spotDate",
                operand: "IS",
                value: 0
            },
            // {
            //     clause: "AND",
            //     field: "status",
            //     operand: "IS",
            //     value: 'A'
            // },
            // {
            //     clause: "AND",
            //     field: "status",
            //     operand: "IS",
            //     value: 'N'
            // },
            // {
            //     clause: "AND",
            //     field: "status",
            //     operand: "IS",
            //     value: 'P'
            // },
            {
                clause: "OR",
                field: "orderType",
                operand: "IS",
                value: 'DOMINB'
            },
            {
                clause: "OR",
                field: "orderType",
                operand: "IS",
                value: 'IMPORT'
            },

        ]

        setIsLoading(true)
        setSnackOpen(true)
        setSnackContent({ severity: "info", message: 'Reloading data from server...!' })
        dispatch(getTraceData(loadsSearchCriteria, ordersLocation.locationNumber, 'trace')).then((res) => {
            setIsLoading(false);
            setRows(res.filter((obj) => obj.lineNumber === 1 && ['IMPORT', 'DOMINB'].includes(obj.orderType)));
            adjustColumnSize();
            setSnackOpen(false)
        });
    }

    const adjustColumnSize = () => {
        if (apiRef.current) {
            apiRef.current.autosizeColumns({
                includeHeaders: false,
                includeOutliers: true,
            })
        }
    }
    /**
     * Update apiRef for edited fields
     * @param {*} newRow 
     * @param {*} oldRow 
     * @returns 
     */
    const processRowUpdate = (
        newRow,
        oldRow,
    ) => {
        const rowId = newRow.id;

        unsavedChangesRef.current.unsavedRows[rowId] = newRow;
        if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
            unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        setHasUnsavedRows(true);
        adjustColumnSize()
        return newRow;
    };

    /**
     * Hanlde orting
     * @param {*} props 
     * @returns 
     */
    const handleSorting = (field) => {

        const existingSortingIndex = sortModel.findIndex((item) => item.field === field);
        let newSorting = [];

        if (existingSortingIndex !== -1) {
            // Toggle sorting direction if column is already being sorted
            newSorting = [...sortModel];
            newSorting[existingSortingIndex] = {
                ...sortModel[existingSortingIndex],
                sort: sortModel[existingSortingIndex].sort === 'asc' ? 'desc' : 'asc',
            };
        } else {
            // Add new column to sorting
            newSorting = [...sortModel, { field, sort: 'asc' }];
        }
        setSortModel(newSorting);
    };

    const CustomEditTextFieldComponent = (props) => {
        const { id, value, field, hasFocus, fieldLength } = props;
        const ref = useRef();

        useLayoutEffect(() => {
            if (hasFocus) {
                ref.current.focus();
            }
        }, [hasFocus]);

        return (
            <TextField
                variant="standard"
                style={{
                    width: 120,
                }}
                onChange={(e) => {
                    switch (field) {
                        case 'unit':
                            apiRef.current.setEditCellValue({
                                id, field, value: e.target.value.toUpperCase().replace(/\s/g, '')
                            })
                            break;
                        case 'chassis':
                            apiRef.current.setEditCellValue({
                                id, field, value: sanitizeStringForDBField(e.target.value.toUpperCase(), 15, true)
                            })
                            break;
                        default:
                            apiRef.current.setEditCellValue({
                                id, field, value: e.target.value.toUpperCase()
                            })
                            break;
                    }

                }

                }
                value={(value === 0) ? '' : value}
                inputProps={{ style: { textTransform: "uppercase" }, maxLength: fieldLength }}
                inputRef={ref}
            />
        )
    }

    /**
     * Table column fields
     */
    const tableColumns = [
        {
            field: 'control',
            headerName: 'Control',
            editable: false,
            filterable: false,
            renderCell: (params) => (<a href={`/orders/${ordersLocation.locationNumber}/${params.row.control}`} target='_blank' >{params.row.control}</a>),
            minWidth: 110,
            flex: 1
        },
        {
            field: 'unit',
            headerName: 'Unit',
            editable: true,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={13} />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'apptDate',
            headerName: 'Appt',
            editable: true,
            filterable: false,
            renderEditCell: (params) => (
                <CustomDatePicker
                    //label="Appt Date"
                    size="small"
                    className="width_100"
                    variant="standard"
                    value={params.row.apptDate || ''}
                    onValueChange={(selectedVal) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: convertYMDToMDY(selectedVal)
                        })
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'apptTime',
            headerName: 'Time',
            editable: true,
            filterable: false,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={8} />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'puloc',
            headerName: 'P/U',
            editable: true,
            renderEditCell: (params) => (
                <PickupPointAutoComplete
                    existingValue={params.row.puloc}
                    valueChange={(selectedVal) => params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: selectedVal
                    })}
                    hasFocus={params.hasFocus}
                />
            ),
            minWidth: 120,
            flex: 1
        },
        {
            field: 'dispLoc',
            headerName: 'Disp',
            editable: false,
            hide: true,
            // renderEditCell: (params) => (
            //     <PickupPointAutoComplete
            //         existingValue={params.row.dispLoc}
            //         valueChange={(selectedVal) => params.api.setEditCellValue({
            //             id: params.id,
            //             field: params.field,
            //             value: selectedVal
            //         })}
            //         hasFocus={params.hasFocus}
            //     />
            // ),
            // minWidth: 120,
            // flex: 1
        },
        {
            field: 'etaDate',
            headerName: 'ETA Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => `${params.row.etaDate} ${params.row.etaTime}`,
            renderEditCell: (params) => (
                <CustomDateTimePicker
                    //label="Notify"
                    variant="standard"
                    size="small"
                    className="width_100"
                    value={`${convertMDYToYMD(params.row.etaDate)}T${params.row.etaTime}` || ''}
                    onValueChange={(selectedVal) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: convertYMDToMDY(selectedVal.eDate)
                        })
                        params.api.setEditCellValue({
                            id: params.id,
                            field: 'etaTime',
                            value: selectedVal.eTime
                        })
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                />
            ),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'etaTime',
            headerName: 'Time',
            editable: true,
            filterable: false,
            hide: true,
            // renderEditCell: (params) => (
            //     <CustomTimePicker
            //         //label="ETA Time"
            //         variant="standard"
            //         size="small"
            //         className="width_100"
            //         value={params.row.etaTime || ''}
            //         onValueChange={(selectedVal) => {
            //             params.api.setEditCellValue({
            //                 id: params.id,
            //                 field: params.field,
            //                 value: selectedVal
            //             })
            //         }}
            //         hasFocus={params.hasFocus}
            //     />
            // )
        },
        {
            field: 'orderBy',
            headerName: 'Order By',
            editable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'shipperName',
            headerName: 'Shipper',
            editable: true,
            renderEditCell: (params) => (
                <ShipperAutoComplete
                    existingValue={params.row.shipperName}
                    locationNumber={ordersLocation?.locationNumber || 0}
                    valueChange={(selectedVal) => {

                        if (selectedVal !== null) {
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: selectedVal.name
                            });
                            let newShipperItem = {
                                ...shipperArrObj,
                                [params.id]: {
                                    code: sanitizeStringForDBField(selectedVal.code, 7),
                                    name: sanitizeStringForDBField(selectedVal.name, 25),
                                    address: sanitizeStringForDBField(selectedVal.address, 25),
                                    city: sanitizeStringForDBField(selectedVal.city, 25),
                                    state: sanitizeStringForDBField(selectedVal.state, 2),
                                    zip: sanitizeStringForDBField(selectedVal.zip, 10),
                                    phone: sanitizeStringForDBField(selectedVal.phone, 13),
                                    fax: sanitizeStringForDBField(selectedVal.fax, 13),
                                }
                            }
                            setShipperArrObj(newShipperItem)
                        } else {
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: ''
                            });
                            let newShipperItem = {
                                ...shipperArrObj,
                                [params.id]: {
                                    code: '',
                                    name: '',
                                    address: '',
                                    city: '',
                                    state: '',
                                    zip: '',
                                    phone: '',
                                    fax: ''
                                }
                            }
                            setShipperArrObj(newShipperItem)
                        }
                    }
                    }
                    hasFocus={params.hasFocus}
                />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'consigneeName',
            headerName: 'Consignee',
            editable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'city',
            headerName: 'City',
            editable: false,
            filterable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'state',
            headerName: 'State',
            editable: false,
            filterable: false,
            minWidth: 130,
            flex: 1
        },
        {
            field: 'release',
            headerName: 'Release',
            editable: true,
            filterable: false,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={10} />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'booking',
            headerName: 'Booking #',
            editable: false,
            hide:true
            // filterable: false,
            // renderEditCell: (params) => (
            //     <CustomEditTextFieldComponent {...params} fieldLength={10} />
            // ),
            // minWidth: 130,
            // flex: 1
        },
        {
            field: 'notifyDate',
            headerName: 'Notify Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => `${params.row.notifyDate} ${params.row.notifyTime}`,
            renderEditCell: (params) => (
                <CustomDateTimePicker
                    //label="Notify"
                    variant="standard"
                    size="small"
                    className="width_100"
                    value={`${convertMDYToYMD(params.row.notifyDate)}T${params.row.notifyTime}` || ''}
                    onValueChange={(selectedVal) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: convertYMDToMDY(selectedVal.eDate)
                        })
                        params.api.setEditCellValue({
                            id: params.id,
                            field: 'notifyTime',
                            value: selectedVal.eTime
                        })
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                />
            ),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'notifyTime',
            headerName: 'Time',
            editable: true,
            filterable: false,
            hide: true,
            // renderEditCell: (params) => (
            //     <CustomTimePicker
            //         //label="Notify Time"
            //         variant="standard"
            //         size="small"
            //         className="width_100"
            //         value={params.row.notifyTime || ''}
            //         onValueChange={(selectedVal) => {
            //             params.api.setEditCellValue({
            //                 id: params.id,
            //                 field: params.field,
            //                 value: selectedVal
            //             })
            //         }}
            //         hasFocus={params.hasFocus}
            //     />
            // )
        },
        {
            field: 'lfd',
            headerName: 'LFD Date & Time',
            editable: true,
            filterable: false,
            renderCell: (params) => `${params.row.lfd} ${params.row.lfdTime}`,
            renderEditCell: (params) => (
                <CustomDateTimePicker
                    //label="LFD"
                    variant="standard"
                    size="small"
                    className="width_100"
                    value={`${convertMDYToYMD(params.row.lfd)}T${params.row.lfdTime}` || ''}
                    onValueChange={(selectedVal) => {

                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: convertYMDToMDY(selectedVal.eDate)
                        })
                        params.api.setEditCellValue({
                            id: params.id,
                            field: 'lfdTime',
                            value: selectedVal.eTime
                        })
                    }}
                    hasFocus={params.hasFocus}
                    isArrowKeyDisabled={true}
                />
            ),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'lfdTime',
            headerName: 'Time',
            editable: true,
            filterable: false,
            hide: true,
            // renderEditCell: (params) => (
            //     <CustomTimePicker
            //         //label="Notify Time"
            //         variant="standard"
            //         size="small"
            //         className="width_100"
            //         value={params.row.notifyTime || ''}
            //         onValueChange={(selectedVal) => {
            //             params.api.setEditCellValue({
            //                 id: params.id,
            //                 field: params.field,
            //                 value: selectedVal
            //             })
            //         }}
            //         hasFocus={params.hasFocus}
            //     />
            // )
            flex: 1
        },
        {
            field: 'chassis',
            headerName: 'Chassis',
            editable: true,
            filterable: false,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={15} />
            ),
            minWidth: 130,
            flex: 1
        },
        {
            field: 'valid',
            headerName: 'Valid',
            editable: false,
            filterable: false,
            minWidth: 100,
            flex: 1
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            editable: true,
            filterable: false,
            minWidth: 130,
            flex: 1,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={25} />
            ),
        },
        {
            field: 'voyage',
            headerName: 'Voyage',
            editable: true,
            filterable: false,
            minWidth: 130,
            flex: 1,
            renderEditCell: (params) => (
                <CustomEditTextFieldComponent {...params} fieldLength={5} />
            ),
        }
    ]

    /**
     * As per the requirement overriding default tab key behaviour for mui datagrid
     */
    const handleKeyDown = (params, event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            moveFocusToNextColumn(params);
        }
    }

    const moveFocusToNextColumn = (params) => {
        let currentColumnIndex = tableColumns.findIndex((col) => col.field === params.field);
        let nextColumnIndex = currentColumnIndex + 1;

        // Find the next visible column
        while (nextColumnIndex < tableColumns.length && tableColumns[nextColumnIndex].hide) {
            nextColumnIndex++;
        }

        // Wrap around to the first visible column if needed
        if (nextColumnIndex >= tableColumns.length) {
            nextColumnIndex = 0;
            while (nextColumnIndex < tableColumns.length && tableColumns[nextColumnIndex].hide) {
                nextColumnIndex++;
            }
        }

        if (nextColumnIndex < tableColumns.length) {
            const nextColumnField = tableColumns[nextColumnIndex].field;
            apiRef.current.setCellFocus(params.id, nextColumnField);
        }
    };

    return (
        <TraceWrapper>
            <Stack spacing={2}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Stack direction="row" spacing={2}
                        sx={{
                            marginTop: "-42px",
                            position: "absolute",
                            alignSelf: "flex-end"
                        }}
                    >
                        <Typography
                            mr={`10px`}
                            variant="h6"
                            sx={{ textAlign: "center", fontWeight: 600, color: '#0911A7' }}
                        >
                            TRACE
                        </Typography>
                        <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => handleShowAll()}>Show All</Button>
                        <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => handleInvalidRemove()}>Remove Invalid</Button>
                        <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => handleRemoveSelected()}>Remove Selected</Button>
                        <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => handleCopyUnitToClipboard()} disabled={(rowSelectionModel.length > 0) ? false : true}>Copy Units</Button>
                        <Tooltip title="Drop leading zeroes and check digit" arrow>
                            <FormControlLabel
                                control={<Checkbox checked={isDomesticChecked} onChange={(e, value) => setIsDomesticChecked(value)} />}
                                label="Domestic"
                                size="small"
                                disabled={(rowSelectionModel.length > 0) ? false : true}
                            />
                        </Tooltip>
                        <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }}
                            disabled={!hasUnsavedRows}
                            //loading={isSaving}
                            onClick={saveChanges}
                        >Save Changes</Button>
                        <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }}
                            disabled={!hasUnsavedRows || isSaving}
                            onClick={discardChanges}
                        >Discard Changes</Button>
                        <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => setSortModel([])} disabled={(sortModel.length > 0) ? false : true}>Remove Sort</Button>
                    </Stack>

                    <Stack
                        //marginTop={1}
                        sx={{
                            height: window.innerHeight - Number(165),
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: "rgba(11, 67, 135, 1)",
                                color: "rgba(255, 255, 255, 255)",
                            },
                            '& .non-editable': {
                                backgroundColor: "#69696929 !important"
                            },
                            '& .MuiDataGrid-sortIcon': {
                                color: "rgba(255, 255, 255, 255)",
                            },
                            '& .MuiDataGrid-menuIconButton': {
                                color: "rgba(255, 255, 255, 255)",
                            },
                            // '& .MuiIconButton-root': {
                            //     color: "rgba(255, 255, 255, 255)"
                            // },
                            '& .highLightWithRed > .MuiDataGrid-cellContent': {
                                color: "red",
                                fontWeight: "bold"
                            },
                            '& .highLightWithYellow > .MuiDataGrid-cellContent': {
                                backgroundColor: "yellow",
                                color: "rgba(0, 0, 0, 0.87)",
                            },
                            // '& .MuiDataGrid-toolbarContainer': {
                            //     marginTop: "-82px",
                            //     marginBottom: "35px",
                            //     marginLeft: "100px"
                            // }
                            '& .css-a713xs-MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
                                backgroundColor: 'rgb(25 118 210 / 23%)'
                            }
                        }}
                    >
                        <DataGridPro
                            hideFooter={true}
                            hideFooterRowCount={false}
                            // columnHeaderHeight={30}
                            // rowHeight={30}
                            editMode="row"
                            rows={rows}
                            columns={tableColumns}
                            loading={isLoading || isSaving}
                            apiRef={apiRef}
                            //disableRowSelectionOnClick
                            //cellSelection
                            processRowUpdate={processRowUpdate}
                            // ignoreValueFormatterDuringExport
                            sx={{
                                '& .MuiDataGrid-row.row--removed': {
                                    backgroundColor: (theme) => {
                                        return 'rgba(255, 170, 170, 0.3)';
                                    },
                                },
                                '& .MuiDataGrid-row.row--edited': {
                                    backgroundColor: (theme) => {
                                        return 'rgba(255, 254, 176, 0.3)';
                                    },
                                },
                            }}
                            getRowClassName={({ id }) => {
                                const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
                                if (unsavedRow) {
                                    if (unsavedRow._action === 'delete') {
                                        return 'row--removed';
                                    }
                                    return 'row--edited';
                                }
                                return '';
                            }}
                            getCellClassName={(params) => {
                                const regex = /^[A-Za-z]{4}[0-9]{5,7}$/;
                                // if (!regex.test(params.row?.unit) && params?.field === "unit") {
                                //     return 'highLightWithRed';
                                // }

                                if ((convertMDYToYMD(params.row.apptDate) > convertMDYToYMD(params.row.lfd)) && params?.field === "lfd") {
                                    return 'highLightWithRed';
                                }
                                if (params.row.lfdYellowFlag && params?.field === "lfd") {
                                    return 'highLightWithYellow';
                                }
                            }}
                            // checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            // filterMode={filterModel}
                            // onFilterModelChange={(model) => setFilterModel(model)}
                            onSortModelChange={adjustColumnSize}
                            slotProps={{
                                panel: {
                                    anchorEl: filterButtonEl,
                                },
                                toolbar: {
                                    setFilterButtonEl,
                                },
                            }}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        notifyTime: false,
                                        lfdTime: false,
                                        etaTime: false,
                                        booking:false,
                                        dispLoc:false
                                    },
                                }
                            }}
                            onColumnHeaderClick={(column) => handleSorting(column.field)}
                            sortModel={sortModel}
                            onCellKeyDown={handleKeyDown}
                        //onSortModelChange={handleSorting}
                        />
                    </Stack>
                </Box>
            </Stack >
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackOpen(false)} >
                <Alert onClose={() => setSnackOpen(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </TraceWrapper >
    )
}